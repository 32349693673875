import { container } from 'inversify-props';
import { OrganizationApiService } from './services/impl/organization-api.service';
import { IOrganizationApiService } from './services/organization-api.service';

export class OrganizationModule {
  constructor() {
    this.addDependencies();
  }

  private addDependencies(): void {
    container.addTransient<IOrganizationApiService>(OrganizationApiService);
  }
}