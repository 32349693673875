import { RouteConfig } from 'vue-router';

const photoAlbumRoutes: RouteConfig[] = [
  {
    name: 'PhotoAlbum',
    path: '/fotoalbum',
    component: () => import('../pages/PhotoAlbum.vue')
  }
]

export default photoAlbumRoutes;