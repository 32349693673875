import { inject } from 'inversify-props';
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { Organization } from '../models/organization.model';
import { IOrganizationApiService } from '../services/organization-api.service';

@Module
export default class OrganizationStoreModule extends VuexModule {
  private organizationData: Organization | null = null;

  @inject()
  private organizationApiService!: IOrganizationApiService;

  @Action
  public async loadOrganizationData(): Promise<void> {
    const data = await this.organizationApiService.getOrganization(this.context.rootGetters.currentClub.id);
    this.context.commit('setOrganization', data);
  }

  @Mutation
  public setOrganization(organization: Organization): void {
    this.organizationData = organization;
  }
  get organization(): Organization | null {
    return this.organizationData;
  }
}