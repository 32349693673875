import { BaseApiService } from "@/shared/services/base-api.service";
import axios from "axios";
import { IHockeyFoodSsoApiService } from "../hockeyfood-sso.service";

export class HockeyClubSsoApiService extends BaseApiService implements IHockeyFoodSsoApiService {
    private readonly hockeyFoodRoutes = {
        hockeyFood: '',
        sso: 'hockey_food_sso_url'
    }    

    async getHockeyFoodSso(clubId: string, memberId: string) : Promise<any> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.hockeyFoodRoutes.sso}`;
        const response = await axios.get(url);

        if (response.status !== 200) {
            return null;
        }

        return response.data;
    }
}