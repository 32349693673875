import { EventTileDetails } from '@/modules/dashboard/models/event-details.model';
import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { EventDetails } from '../../models/event-details.model';
import { EventGroup } from '../../models/event-group.model';
import { IEventApiService } from '../event-api.service';

@injectable()
export class EventApiService extends BaseApiService implements IEventApiService {
  private readonly eventsRoutes = {
    events: 'events',
    subscribe: 'subscribe',
    reject: 'reject',
    payment: 'payment-url',
    tile: 'tile'
  }

  async getEvents(clubId: string, query: { showOld: boolean, showOnlySignUp: boolean }): Promise<EventGroup[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.events}`;
    const response = await axios.get(url, { params: { showOld: query.showOld, showOnlySignUp: query.showOnlySignUp }});
    return response.data.eventGroups;
  }

  async getEventDetails(clubId: string, eventId: string): Promise<EventDetails> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.events}/${eventId}`;
    const response = await axios.get(url);
    return response.data.event;
  }

  async subscribeEvent(clubId: string, eventId: string, memberId: string, numberOfGuests: number): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.events}/${eventId}/${this.eventsRoutes.subscribe}`;
    const response = await axios.post(url, { memberId, numberOfGuests });
    return response.status === 201;
  }

  async rejectEvent(clubId: string, eventId: string, memberId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.events}/${eventId}/${this.eventsRoutes.reject}`;
    const response = await axios.delete(url, { data: { memberId } });
    return response.status === 201;
  }

  async getPaymentUrl(clubId: string, eventId: string, memberId: string): Promise<string> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.events}/${eventId}/${this.eventsRoutes.payment}`;
    const response = await axios.get(url, { params: { memberId, callbackUrl: encodeURIComponent(window.location.href)}});
    return response.data.paymentUrl;
  }

  async getEventDetailsTile(clubId: string, eventId: string) : Promise<EventTileDetails> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.events}/${eventId}/${this.eventsRoutes.tile}`;
    const response = await axios.get(url);

    return response.data;
}
}