import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { inject } from 'inversify-props';
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { ClubSelectItem } from '../models/club-select-item.model';
import { Match } from '../models/match.model';
import { MyTeamListItem } from '../models/my_teams.model';
import { TeamLabels } from '../models/team-labels.model';
import { Team } from '../models/team.model';
import { ITeamApiService } from '../services/team.service';
import { TrainingPresence } from '../models/training-presence.model';
import { Training } from '../models/training.model';
import { identifier, ITeamTrainingApiService } from '../services/team-training-api.service';
import { TeamTaskMatch } from '../models/team-task-match.model';
import { identifier as teamTaskIdentifier, ITeamTaskApiService } from '../services/team-task-api.service';
import { TeamTaskType } from '../models/team-task-type.model';
import { TeamTaskMember } from '../models/team-task-member.model';
import { NewTaskType } from '../models/new-task-type.model';
import { TeamTaskMatchTask } from '../models/team-task-match-task.model';
import { TeamMembers } from '../models/team-members.model';
import { TeamMemberDetails } from '../models/team-member-details.model';
import { NewTeamDeposit } from '../models/new-team-deposit.model';
import { ITeamDepositApiService, identifier as teamDepositIdentifier} from '../services/team-deposit-api.service';
import { PouleMatch } from '../models/poule-match.model';
import { TeamResult } from '../models/team-result.model';
import { SendEmailRequest } from '../models/send-email-request.model';
import { TrainingsPaged } from '../models/trainings-paged.model';

@Module
export default class TeamStoreModule extends VuexModule {
  private trainings: Training[] = [];
  private trainingPresence: TrainingPresence | null = null;
  private matches: Match[] = [];
  private team: Team | null = null;
  private taskMatches: TeamTaskMatch[] = [];
  private myTeams: MyTeamListItem[] = []
  private myTeamsLabels: TeamLabels[] = [];
  private clubSelectItems: ClubSelectItem[] = [];
  private taskTypes: TeamTaskType[] = [];
  private taskMembers: TeamTaskMember[] = [];
  private members: TeamMembers | null = null;
  private teamMember: TeamMemberDetails | null = null;
  private teamPouleMatches: PouleMatch[] = [];
  private teamResultsData: TeamResult[] = [];
  private pager: any = {
    pageNumber: 1,
    pageSize: 10,
    totalEntries: 0,
    totalPages: 0
  }

  private teamMemberListReload = false;

  @inject()
  private teamApiService!: ITeamApiService;
  @inject(teamDepositIdentifier)
  private teamDepositApiService!: ITeamDepositApiService;
  @inject(identifier)
  private teamTrainingApiService!: ITeamTrainingApiService;
  @inject(teamTaskIdentifier)
  private teamTaskApiService!: ITeamTaskApiService

  @Action
  public async loadTeamTrainings(payload: { teamId: string, showHistory: boolean, currentPage: number, pageSize: number}): Promise<void> {
    const data = await this.teamTrainingApiService.getTeamTrainings(this.context.rootGetters.currentClub.id, payload.teamId, payload.showHistory, payload.currentPage, payload.pageSize);
    this.context.commit('setTeamTrainings', data);
  }

  @Action
  public async loadTeamTrainingPresence(payload: { teamId: string, trainingId: string, occurenceId: string}): Promise<void> {
    const data = await this.teamTrainingApiService.getTrainingPresence(
      this.context.rootGetters.currentClub.id,
      payload.teamId,
      payload.trainingId,
      payload.occurenceId
    );
    this.context.commit('setTeamTrainingPresence', data);
  }

  @Action
  public async changeUserTrainingPresence(payload: { teamId: string, trainingId: string, status: PresenceStatus}): Promise<void> {
    const data = await this.teamTrainingApiService.changePresenceStatus(
      this.context.rootGetters.currentClub.id,
      payload.teamId,
      payload.trainingId,
      this.context.rootGetters.currentMember.id,
      payload.status  
    )
    if (!data) {
      throw new Error('Failed to change presence');
    }
  }

  @Action
  public async loadMatches(payload: { teamId: string, showHistoricalMatches: boolean }): Promise<void> {
    const data = await this.teamApiService.getTeamMatches(this.context.rootGetters.currentClub.id, payload.teamId, payload.showHistoricalMatches);
    this.context.commit('setTeamMatches', data);
  }

  @Action
  public async loadTeamMembers (payload: { teamId: string }): Promise<void> {
    const data = await this.teamApiService.getTeamMembers(this.context.rootGetters.currentClub.id, payload.teamId);
    this.context.commit('setTeamMembers', data);
  }

  @Action
  public async changeMemberTrainingPresence(payload: { teamId: string, trainingId: string, memberId: string, status: PresenceStatus}): Promise<void> {
    const data = await this.teamTrainingApiService.changePresenceStatus(
      this.context.rootGetters.currentClub.id,
      payload.teamId,
      payload.trainingId,
      payload.memberId,
      payload.status
    )
    if (!data) {
      throw new Error('Failed to change presence');
    }
  }

  @Action
  public async loadTeamInfo(payload: { teamId: string }): Promise<void> {
    const data = await this.teamApiService.getTeam(this.context.rootGetters.currentClub.id, payload.teamId);
    this.context.commit('setTeam', data);
  }

  @Action
  public async loadTeamTasks(payload: { teamId: string, showHistory: boolean }): Promise<void> {
    const data = await this.teamTaskApiService.getTeamTaskMatches(this.context.rootGetters.currentClub.id, payload.teamId, payload.showHistory);
    this.context.commit('setTaskMatches', data);
  }

  @Action
  public async loadAllMyTeams(payload: { clubGuid: string, teamLabels: number[], onlyFavourites: boolean }) : Promise<void> {
    const data = await this.teamApiService.getMyAllTeams(this.context.rootGetters.currentClub.id, payload.clubGuid, payload.teamLabels, payload.onlyFavourites);
    this.context.commit('setMyTeams', data.teams);
  }

  @Action
  public async loadAllMyTeamsLabels() : Promise<void> {
    const data = await this.teamApiService.getTeamLabels(this.context.rootGetters.currentClub.id);
    this.context.commit('setMyTeamsLabels', data);
  }

  @Action
  public async loadClubsSelectList() : Promise<void> {
    const data = await this.teamApiService.getClubsSelectList(this.context.rootGetters.currentClub.id, this.context.rootGetters.currentFederation.id);
    this.context.commit('setClubSelectItems', data);
  }

  @Action 
  public async changeFavouriteStatus(payload: { teamId: string, status: boolean }) : Promise<void> {
    await this.teamApiService.changeFavouriteStatus(this.context.rootGetters.currentFederation.id, this.context.rootGetters.currentClub.id, payload.teamId, payload.status);
    this.context.commit('setFavouriteStatus', { teamId: payload.teamId, status: payload.status });
  }

  @Action
  public async loadTeamMemberDetails(payload: { teamId: string, memberId: string}): Promise<void> {
    const data = await this.teamApiService.getTeamMemberDetails(this.context.rootGetters.currentClub.id, payload.teamId, payload.memberId);
    this.context.commit('setTeamMemberDetails', data);
  }

  @Action
  public async loadTeamTaskTypes(teamId: string): Promise<void> {
    const data = await this.teamTaskApiService.getTeamTaskTypes(this.context.rootGetters.currentClub.id, teamId);
    this.context.commit('setTeamTaskTypes', data);
  }

  @Action
  public async loadTeamTaskMembers(teamId: string): Promise<void> {
    const data = await this.teamTaskApiService.getTeamTaskMembers(this.context.rootGetters.currentClub.id, teamId);
    this.context.commit('setTeamTaskMembers', data);
  }

  @Action
  public async deleteMatchTaskFromMember(payload: { teamId: string, matchId: string, taskId: string}): Promise<void> {
    const status = await this.teamTaskApiService.deleteTaskAssignment(this.context.rootGetters.currentClub.id, payload.teamId, payload.matchId, payload.taskId);
    if (status !== 204) {
      throw new Error(status.toString());
    }
  }
  
  @Action
  public async assignMatchTaskToMember(payload: { teamId: string, matchId: string, teamPersonId: string, taskTypeId: string}): Promise<void> {
    const response = await this.teamTaskApiService.assignTaskToMember(
      this.context.rootGetters.currentClub.id, payload.teamId, payload.matchId, payload.teamPersonId, payload.taskTypeId
    );
    if (response.status !== 201) {
      throw new Error(status.toString());
    }

    this.context.commit('addTeamTask', { matchId: payload.matchId, task: response.result });
  }

  @Action
  public async createTaskType(payload: { teamId: string, taskType: NewTaskType }): Promise<void> {
    const response = await this.teamTaskApiService.createTaskType(this.context.rootGetters.currentClub.id, payload.teamId, payload.taskType);
    if (!response) {
      throw new Error('Failed to create task type!');
    }
  }

  @Action
  public async updateTaskType(payload: { teamId: string, taskTypeId: string, taskType: NewTaskType }): Promise<void> {
    const response = await this.teamTaskApiService.updateTaskType(this.context.rootGetters.currentClub.id, payload.teamId, payload.taskTypeId, payload.taskType);
    if (!response) {
      throw new Error('Failed to update task type!');
    }
  }

  @Action
  public async deleteTaskType(payload: { teamId: string, taskTypeId: string }): Promise<void> {
    const reponse = await this.teamTaskApiService.deleteTaskType(this.context.rootGetters.currentClub.id, payload.teamId, payload.taskTypeId);
    if (!reponse) {
      throw new Error('Failed to delete task type!');
    }
  }

  @Action
  public async createTeamDeposit(payload: { teamId: string, memberId: string, deposit: NewTeamDeposit }): Promise<void> {
    const response = await this.teamDepositApiService.createTeamDeposit(this.context.rootGetters.currentClub.id, payload.teamId, payload.memberId, payload.deposit);
    if (!response) {
      throw new Error('Failed to create deposit!');
    }
  }

  @Action
  public async deleteTeamDeposit(payload: { teamId: string, memberId: string, depositId: string }): Promise<void> {
    const reponse = await this.teamDepositApiService.deleteTeamDeposit(this.context.rootGetters.currentClub.id, payload.teamId, payload.memberId, payload.depositId);
    if (!reponse) {
      throw new Error('Failed to delete deposit!');
    }
  }

  @Action
  public async loadPouleUpcomingMatches(payload: { teamId: string, pouleId: string, showAll: boolean}): Promise<void> {
    const response = await this.teamApiService.getTeamPoulUpcomingMatches(this.context.rootGetters.currentClub.id, payload.teamId, payload.pouleId, payload.showAll);
    this.context.commit('setPouleUpcomingMatches', response);
  }

  @Action
  public async loadTeamResults(teamId: string): Promise<void> {
    const response = await this.teamApiService.getTeamResults(this.context.rootGetters.currentClub.id, teamId);
    this.context.commit('setTeamResults', response);
  }

  @Action
  public async sendEmail(payload: { teamId: string, request: SendEmailRequest }): Promise<void> {
    const response = await this.teamApiService.sendEmail(this.context.rootGetters.currentClub.id, payload.teamId, payload.request);
    if (!response) {
      throw new Error('Failed to send email');
    }
  }

  @Mutation
  public setTeamMatches(matches: Match[]): void {
    this.matches = matches;
  }

  @Mutation
  public setTeamMemberDetails(teamMemberDetails: TeamMemberDetails): void {
    this.teamMember = teamMemberDetails;
  }

  @Mutation
  public setTeamTrainings(result: TrainingsPaged): void {
    this.trainings = result.items;
    this.pager.pageNumber = result.page.pageNumber;
    this.pager.pagesize = result.page.pageSize;
    this.pager.totalEntries = result.page.totalEntries;
    this.pager.totalPages = result.page.totalPages; 
  }
  @Mutation
  public setTeamTrainingPresence(training: TrainingPresence): void {
    this.trainingPresence = training;
  }
  @Mutation
  public addTeamTask(payload: {matchId: string, task: TeamTaskMatchTask}): void {
    const matchIndex = this.taskMatches.findIndex(x => x.id === payload.matchId);
    this.taskMatches[matchIndex].tasks.push(payload.task);
  }

  @Mutation
  public setTeam(team: Team): void {
    this.team = team;
  }

  @Mutation
  public setTaskMatches(tasks: TeamTaskMatch[]): void {
    this.taskMatches = tasks;
  }

  @Mutation
  public setTeamMembers(teamMembers: TeamMembers): void {
    this.members = teamMembers;
  }

  @Mutation
  public setMyTeamsLabels(myTeamsLabels: TeamLabels[]): void {
    this.myTeamsLabels = myTeamsLabels;
  }

  @Mutation
  public setMyTeams(teams: MyTeamListItem[]): void {
    this.myTeams = teams;
  }

  @Mutation
  public setClubSelectItems(clubs: ClubSelectItem[]): void {
    this.clubSelectItems = clubs;
  }

  @Mutation
  public setFavouriteStatus(payload: { teamId: string, status: boolean}): void {
    const index = this.myTeams.findIndex(x => x.id === payload.teamId);

    if (index !== -1) {
      this.myTeams[index].isFavourite = payload.status;
    }
  }

  @Mutation
  public setTeamTaskTypes(types: TeamTaskType[]): void {
    this.taskTypes = types;
  }

  @Mutation
  public setTeamTaskMembers(members: TeamTaskMember[]): void {
    this.taskMembers = members;
  }
  
  @Mutation
  public setPouleUpcomingMatches(matches: PouleMatch[]): void {
    this.teamPouleMatches = matches;
  }

  @Mutation
  public setTeamResults(results: TeamResult[]): void {
    this.teamResultsData = results;
  }

  @Mutation
  public setTeamMembersListReload(reload: boolean) : void {
    this.teamMemberListReload = reload;
  }

  @Mutation
  public setCurrentPage(page: number) : void {
    this.pager.pageNumber = page;
  }
  
  @Mutation
  public setCurrentPageSize(page: number) : void {
    this.pager.pageSize = page;
  }

  @Mutation
  public resetPager() : void {
    this.pager.pageNumber = 1;
    this.pager.totalEntries = 0;
    this.pager.totalPages = 0; 
  }

  get teamTrainings(): Training[] {
    return this.trainings;
  }

  get teamInfo(): Team | null {
    return this.team;
  }

  get teamTrainingPresence(): TrainingPresence | null {
    return this.trainingPresence;
  }

  get teamMatches(): Match[] {
    return this.matches;
  }

  get teamMembers(): TeamMembers | null {
    return this.members;
  }

  get teamMemberDetails(): TeamMemberDetails | null {
    return this.teamMember;
  }

  get selectedTeam() {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (teamId: string) => this.context.rootGetters.currentMemberPersonalTeams.find((x: Team) => x.id === teamId)
  }

  get teamTaskMatches(): TeamTaskMatch[] {
    return this.taskMatches;
  }

  get teams(): MyTeamListItem[] {
    return this.myTeams;
  }

  get teamTaskTypes(): TeamTaskType[] {
    return this.taskTypes;
  }

  get teamTaskMembers(): TeamTaskMember[] {
    return this.taskMembers;
  }
  
  get teamLabels(): TeamLabels[] {
    return this.myTeamsLabels;
  }

  get clubSelectList() : ClubSelectItem[] {
    return this.clubSelectItems;
  }

  get teamPouleUpocomingMatches(): PouleMatch[] {
    return this.teamPouleMatches;
  }

  get teamResults(): TeamResult[] {
    return this.teamResultsData;
  }

  get reloadTeamMembersList() : boolean {
    return this.teamMemberListReload;
  }

  get pagination() : any {
    return this.pager;
  }
}