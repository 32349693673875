import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { ClubSelectItem } from '../../models/club-select-item.model';
import { MatchPresence } from '../../models/match-presence.model';
import { Match } from '../../models/match.model';
import { PouleMatch } from '../../models/poule-match.model';
import { SendEmailRequest } from '../../models/send-email-request.model';
import { TeamLabels } from '../../models/team-labels.model';
import { TeamListResponse } from '../../models/team-list-response.model';
import { TeamMemberDetails } from '../../models/team-member-details.model';
import { TeamMembers } from '../../models/team-members.model';
import { TeamPouleResults } from '../../models/team-poule-results.model';
import { TeamResult } from '../../models/team-result.model';
import { Team } from '../../models/team.model';
import { ITeamApiService } from '../team.service';
import { PouleSelectListItem } from '../../models/poule-select-list-item.model';

@injectable()
export class TeamApiService extends BaseApiService implements ITeamApiService {

  private readonly eventsRoutes = {
    team: 'teams',
    match: 'matches',
    presence: 'presences',
  }

  private readonly allTeamsEventRoutes = {
    allTeams: 'all-teams',
    teams: 'teams'
  }

  private readonly teamsLablesEventRoutes = {
    allTeams: 'teams',
    teamsLabels: 'team-labels'
  }

  private readonly teamMembersEventRoutes = {
    teams: 'teams',
    teamMembers: 'team-members',
    email: 'emails'
  }

  private readonly clubSelectEventRoutes = {
    federations: 'federations',
    clubs: 'clubs',
    select: 'select'
  }

  private readonly pouleMatchesRoutes = {
    teams: 'teams',
    poules: 'poules',
    matches: 'matches'
  }
  
  private readonly teamResultsRoutes = {
    teams: 'teams',
    matches: 'matches',
    results: 'results'
  }

  async getTeamMembers(clubId: string, teamId: string): Promise<TeamMembers> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.team}/${teamId}/${this.teamMembersEventRoutes.teamMembers}`;
    const params = { teamId };
    const response = await axios.get(url, { params });
    return response.data;
  }

  async getTeamMemberDetails(clubId: string, teamId: string, memberId: string): Promise<TeamMemberDetails> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.team}/${teamId}/${this.teamMembersEventRoutes.teamMembers}/${memberId}`;
    const params = { teamId };
    const response = await axios.get(url, { params });
    return response.data;
  }

  private readonly selectTeamPoulesRoutes = {
    teams: 'teams',
    selects: "selects",
    poules: 'poules'
  }

  private readonly teamPouleRankingRoutes = {
    teams: 'teams',
    poule: 'poule',
    ranking: 'rankings'
  }

  async getTeamMatches(clubId: string, teamId: string, showHistoricalMatches: boolean): Promise<Match[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.team}/${teamId}/${this.eventsRoutes.match}`;
    const params = { showHistoricalMatches };
    const response = await axios.get(url, { params });
    return response.data;
  }

  async getTeam (clubId:string, teamId: string) : Promise<Team> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.team}/${teamId}`;
    const response = await axios.get(url);
    return response.data;
  }

  async getMatchPresence(clubId:string, teamId: string, matchId: string) : Promise<MatchPresence> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.eventsRoutes.team}/${teamId}/${this.eventsRoutes.match}/${matchId}/${this.eventsRoutes.presence}`;
    const response = await axios.get(url);
    return response.data;
  }

  async getMyAllTeams(clubId: string, clubGuid: string, teamLabels: number[], onlyFavourites: boolean) : Promise<TeamListResponse> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.allTeamsEventRoutes.allTeams}/${this.allTeamsEventRoutes.teams}`;
    const params = { 
      clubGuid: clubGuid, 
      onlyFavourites: onlyFavourites, 
      team_label_guids: teamLabels
    };

    const response = await axios.get(url, { params });
    return response.data;
  }

  async getTeamLabels(clubId: string) : Promise<TeamLabels[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamsLablesEventRoutes.allTeams}/${this.teamsLablesEventRoutes.teamsLabels}`;
    const response = await axios.get(url);
    return response.data;    
  }

  async getClubsSelectList(clubId: string, federationId: string) : Promise<ClubSelectItem[]> {
    const url = `${this.BASE_URL}/${this.clubSelectEventRoutes.federations}/${federationId}/${this.clubSelectEventRoutes.clubs}/${this.clubSelectEventRoutes.select}`;
    const response = await axios.get(url);

    return response.data;
  }

  async changeFavouriteStatus(federationId: string, clubId: string, teamId: string, favouriteStatus: boolean) : Promise<void> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.allTeamsEventRoutes.allTeams}/${this.allTeamsEventRoutes.teams}/favourite/${federationId}/${teamId}`;
    const params = {
      isFavourite: favouriteStatus
    };

    await axios.post(url, null, { params });
  }

  async getTeamPoulesForSelect(clubId: string, teamId: string) : Promise<PouleSelectListItem[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.selectTeamPoulesRoutes.selects}/${this.selectTeamPoulesRoutes.teams}/${teamId}/${this.selectTeamPoulesRoutes.poules}`;
    const response = await axios.get(url);

    return response.data;
  }

  async getTeamPouleRankings(clubId: string, pouleId: string) : Promise<TeamPouleResults> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamPouleRankingRoutes.teams}/${this.teamPouleRankingRoutes.poule}/${pouleId}/${this.teamPouleRankingRoutes.ranking}`;
    const response = await axios.get(url);

    return response.data;
  }

  async getTeamPoulUpcomingMatches(clubId: string, teamId: string, pouleId: string, showAll: boolean): Promise<PouleMatch[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.pouleMatchesRoutes.teams}/${teamId}/${this.pouleMatchesRoutes.poules}/${pouleId}/${this.pouleMatchesRoutes.matches}`;
    const response = await axios.get(url, { params: { showAll }});

    return response.data;
  }

  async getTeamResults(clubId: string, teamId: string): Promise<TeamResult[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamResultsRoutes.teams}/${teamId}/${this.teamResultsRoutes.matches}/${this.teamResultsRoutes.results}`;
    const response = await axios.get(url);

    return response.data.results;
  }

  async sendEmail(clubId: string, teamId: string, request: SendEmailRequest): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamMembersEventRoutes.teams}/${teamId}/${this.teamMembersEventRoutes.email}`;
    const response = await axios.post(url, request);
    return response.status === 200;
  }
}