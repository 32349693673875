import { container } from 'inversify-props';
import { IEventApiService } from './services/event-api.service';
import { EventApiService } from './services/impl/event-api.service';

export class EventModule {
  constructor() {
    this.addDependencies();
  }

  private addDependencies() {
    container.addTransient<IEventApiService>(EventApiService);
  }
}