import { inject } from "inversify-props";
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { DocumentCategory } from "../models/document-category.model";
import { identifier, IDocumentApiService } from "../services/doument.service";

@Module
export default class DocumentStoreModule extends VuexModule {
    private categories: DocumentCategory[] = [];
    private documentExists!: boolean;

    @inject(identifier)
    private documentApiService!: IDocumentApiService;

    @Action
    public async loadCategoriesWithDocuments() : Promise<void> {
        const data = await this.documentApiService.getDocumentsWithCategories(this.context.rootGetters.currentClub.id);
        this.context.commit('setCategories', data);
    }

    @Action
    public async checkIfAnyDocumentExists() : Promise<void> {
        const data = await this.documentApiService.checkIfAnyDocumentExists(this.context.rootGetters.currentClub.id);     
        this.context.commit('setAnyDocumentExists', data);
    }

    @Mutation
    public setCategories(categories: DocumentCategory[]) {
        this.categories = categories;
    }

    @Mutation
    public setAnyDocumentExists(anyDocumentExists: boolean) {        
        this.documentExists = anyDocumentExists;
    }

    get categoriesWithDocuments() : DocumentCategory[] {
        return this.categories;
    }

    get anyDocumentExists() : boolean {
        return this.documentExists;
    }
}