import { BaseApiService } from "@/shared/services/base-api.service";
import { injectable } from "inversify-props";
import axios from "axios";
import { ISurveyApiService } from "../surveys.service";
import { FillSurveyResponse } from "../../models/fill-survey-response.model";
import { SurveyQuestionGroup } from "../../models/survey-question-group.model";

@injectable()
export class SurveyApiService extends BaseApiService implements ISurveyApiService {

    private readonly surveyRoutes = {
        my: 'my',
        fillSurvey: 'fillsurvey',
        start: "start",
        resume: "resume",
        nextQuestionGroup: "next",
        previousQuestionGroup: "prev",
        finnish: "finnish"
    }

    async getFillSurvey(token: string, eventId?: string | null, eventToken?: string | null): Promise<FillSurveyResponse> {
        const url = `${this.BASE_URL}/${this.surveyRoutes.fillSurvey}`;

        const response = await axios.post(url, {
            token,
            eventId,
            eventToken
        })

        return response.data;
    }
    
    async startSurvey(clubId: string, surveyId: string, personId: string, respondentId: string, eventId: string | null, eventToken: string | null): Promise<SurveyQuestionGroup> {
        const url = `${this.BASE_URL}/${clubId}/${this.surveyRoutes.fillSurvey}/${this.surveyRoutes.start}/${surveyId}`;

        const response = await axios.post(url, {
            personId,
            respondentId,
            eventId,
            eventToken
        })

        return response.data;
    }

    async resumeSurvey(clubId: string, surveyId: string, personId: string, respondentId: string, eventId: string | null, eventToken: string | null): Promise<SurveyQuestionGroup> {
        const url = `${this.BASE_URL}/${clubId}/${this.surveyRoutes.fillSurvey}/${this.surveyRoutes.resume}/${surveyId}`;

        const response = await axios.post(url, {
            personId,
            respondentId,
            eventId,
            eventToken
        })

        return response.data;
    }

    async nextQuestionGroup(clubId: string, surveyId: string, model: SurveyQuestionGroup): Promise<SurveyQuestionGroup> {
        const url = `${this.BASE_URL}/${clubId}/${this.surveyRoutes.fillSurvey}/${this.surveyRoutes.nextQuestionGroup}/${surveyId}`;

        const response = await axios.post(url, model)

        return response.data;
    }

    async peviousQuestionGroup(clubId: string, surveyId: string, personId: string, respondentId: string, order: number, eventId: string | null, eventToken: string | null): Promise<SurveyQuestionGroup> {
        const url = `${this.BASE_URL}/${clubId}/${this.surveyRoutes.fillSurvey}/${this.surveyRoutes.previousQuestionGroup}/${surveyId}`;

        const response = await axios.post(url, { 
            personId,
            respondentId,
            eventId,
            eventToken,
            order
         })

        return response.data;
    }

    async finnishSurvey(clubId: string, surveyId: string, model: SurveyQuestionGroup) : Promise<void> {
        const url = `${this.BASE_URL}/${clubId}/${this.surveyRoutes.fillSurvey}/${this.surveyRoutes.finnish}/${surveyId}`;

        const response = await axios.post(url, model)

        if (response.status !== 200) {
            throw Error();
        }
    }
}