import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import SpinnerButton from './components/SpinnerButton.vue';
import { DateHelper } from './helpers/date.helper';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ParseInterceptor } from './interceptors/parse.interceptor';

export class SharedModule {
  constructor() {
    this.declareComponents();
    this.addInterceptors()
  }

  private addInterceptors() {
    AuthInterceptor.setup();
    ParseInterceptor.setup();
  }

  private declareComponents() {
    Vue.component('SpinnerButton', SpinnerButton)
    Vue.component('multiselect', Multiselect);
    Vue.filter("date", (str: string) => DateHelper.getDate(str))
    Vue.filter("time", (str: string) => DateHelper.getTime(str));
    Vue.filter("array", (array: string[]) => array.length > 0 ? array.join(', ') : '-')
  }
}