import { RouteConfig } from 'vue-router';

const invoicesRoutes: RouteConfig[] = [
  {
    name: 'Invoices',
    path: '/facturen',
    component: () => import('../pages/Invoices.vue')
  }
]

export default invoicesRoutes;