import { RouteConfig } from 'vue-router';

const defaultRoutes: RouteConfig[] = [
  {
    name: 'NotFound',
    path: '*',
    component: () => import('../pages/NotFound.vue')
  }
]

export default defaultRoutes;