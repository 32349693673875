import { ContractDetailsWrapped } from "../models/contract-details-wrapped.model";
import ContractHoursForm from "../models/contract-hours-form.model";
import { ContractWrapped } from "../models/contract-wrapped.model";
import { SelectListItem } from "../models/select-list-item.model";

export interface IContractsApiService {
    getContractList(clubId: string, showExpired: boolean) : Promise<ContractWrapped>
    getContractDetails(clubId: string, contractId: string) : Promise<ContractDetailsWrapped>

    getHourTypes(clubId: string) : Promise<SelectListItem[]>
    getMemberTeams(clubId: string, date: string | null) : Promise<SelectListItem[]>

    addContractHours(clubId: string, contractId: string, model: ContractHoursForm) : Promise<void>
    getForUpdateContractHours(clubId: string, contractId: string, contractHoursId: string) : Promise<ContractHoursForm>
    updateContractHours(clubId: string, contractId: string, contractHoursId: string, model: ContractHoursForm) : Promise<void>
    deleteContractHours(clubId: string, contractId: string, contractHoursId: string) : Promise<void>
}
export const identifier = 'CONTRACTS_API_SERVICE';
