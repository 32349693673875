import { inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CustomFieldAnswer, CustomFieldType } from "../models/custom-field-answer.model";
import { CustomFieldGroup } from "../models/custom-field-group.model";
import { ICustomFieldsApiService } from "../services/custom-fields-api.service";
import { IMembershipCancellationsApiService } from "../services/membership-cancellations-api.service";
import { CancellationFormSettings } from "../models/cancellation-form-settings.model";
import { Membership } from "../models/membership.model";
import { CancellationReason } from "../models/cancellation-reason.model";
import { CancellMembershipsRequest } from "../models/cancell-memberships-request.model";
import dayjs from "dayjs";

@Module
export default class ProfileStoreModule extends VuexModule {

    @inject()
    private customFieldsApiService!: ICustomFieldsApiService;
    @inject()
    private membershipCancellationsApiService!: IMembershipCancellationsApiService;

    private groups: CustomFieldGroup[] = [];
    private answers: CustomFieldAnswer[] = [];

    private cancellationFormSettings: CancellationFormSettings | null = null;
    private memberships: Membership[] = [];
    private cancellationReasons: CancellationReason[] = [];

    @Action
    public async loadCustomFieldAnswers() : Promise<void> {
        const data = await this.customFieldsApiService.getAnswers(this.context.rootGetters.currentClub.id);
        const fieldsFiltered = data.customFieldAnswers.filter(x => x.personCanEdit === true);

        this.context.commit('setCustomFieldAnswers', fieldsFiltered);
        this.context.commit('setCustomFieldGroups', fieldsFiltered);
    }

    @Action
    public async updateCustomFieldAnswer(payload: { fieldId: string, value: string | null }) : Promise<void> {
        const response = await this.customFieldsApiService.updateAnswer(this.context.rootGetters.currentClub.id, payload.fieldId, payload.value);

        if (!response) {
            throw new Error('Failed to update answer');
        }

        this.context.commit('updateCustomFieldNaswer', payload);
    }

    @Action
    public async sendMembershipCancellations(payload: CancellMembershipsRequest) : Promise<void> {
        const response = await this.membershipCancellationsApiService.cancellMemberships(this.context.rootGetters.currentClub.id, payload);

        if (!response) throw new Error('Failed to save membership extensions');
    }

    @Action
    public async loadCancellationFormSettings() : Promise<void> {
        const response = await this.membershipCancellationsApiService.getFormSettings(this.context.rootGetters.currentClub.id);
        this.context.commit('setFormSettings', response);
    }

    @Action
    public async loadMemberships() : Promise<void> {
        const response = await this.membershipCancellationsApiService.getMemberships(this.context.rootGetters.currentClub.id);
        this.context.commit('setMemberships', response.memberships);
    }

    @Action
    public async loadCancellationReasons() : Promise<void> {
        const response = await this.membershipCancellationsApiService.getCancellationReasons(this.context.rootGetters.currentClub.id);
        this.context.commit('setCancellationReasons', response.reasons);
    }

    @Mutation
    public setCustomFieldGroups(data: CustomFieldAnswer[]) {
        const groups = [...data]
            .map((x) => {
                return {
                    id: x.customFieldGroupId,
                    order: x.groupOrder,
                    name: x.groupName
                }
            });     
            
        const result = Array.from(new Set(groups.map(s => s.id)))
            .map(id => {
                return groups.find(s => s.id === id);
            }) as CustomFieldGroup[] || [];

        this.groups = result;
    }

    @Mutation
    public setCustomFieldAnswers(data: CustomFieldAnswer[]) {
        this.answers = data;
    }

    @Mutation
    public updateCustomFieldNaswer(payload: { fieldId: string, value: string | null }) {
        const field = this.answers.find(x => x.customFieldId == payload.fieldId);

        if (!field) {
            return;
        }

        switch (field.customFieldType) {
            case CustomFieldType.Bool:
                field.boolValue = payload.value === null ? null : (payload.value === 'True' ? true : false);
                break;
            case CustomFieldType.Int:
                field.intValue = payload.value === null ? null : Number(payload.value);
                break;
            case CustomFieldType.Date:
                field.datetimeValue = payload.value;
                break;
            case CustomFieldType.Text:
            case CustomFieldType.Color:
                field.textValue = payload.value;
                break;
            case CustomFieldType.SelectList:
            case CustomFieldType.DecisionSelectList:
                field.guidValue = payload.value;
                break;
        }

        field.canEdit = field.personCanEdit && (payload.value === null || field.personCanChangeValue);

        if (field.customFieldType === CustomFieldType.Date && payload.value)  {
            field.valueAsString = dayjs(payload.value, 'YYYY-MM-DD').format('D-M-YYYY');
        }
        else if ((field.customFieldType === CustomFieldType.SelectList || field.customFieldType === CustomFieldType.DecisionSelectList) && payload.value) {
            const selectedValue = field.selectListAvailableValues.find(x => x.guid == payload.value);
            field.valueAsString = selectedValue?.name ?? null;
        }
        else {
            field.valueAsString = payload.value;
        }
    }

    @Mutation
    public setFormSettings(data: CancellationFormSettings) {
        this.cancellationFormSettings = data;
    }

    @Mutation
    public setMemberships(data: Membership[]) {
        this.memberships = data;
    }

    @Mutation
    public setCancellationReasons(data: CancellationReason[]) {
        this.cancellationReasons = data;
    }

    get customFieldGroups() : CustomFieldGroup[] {
        return this.groups || [];
    }

    get customFieldAnswers() : CustomFieldAnswer[] {
        return this.answers || [];
    }

    get membershipCancellationReasons() : CancellationReason[] {
        return this.cancellationReasons || [];
    }

    
    get membershipsToCancel() : Membership[] {
        return this.memberships || [];
    }
    
    get membershipCancellationFormSettings() : CancellationFormSettings | null {
        return this.cancellationFormSettings;
    }
}