import { container } from 'inversify-props';
import { IFederationApiService } from './services/federation-api.service';
import { IAuthApiService } from './services/auth-api.service';
import { IClubApiService } from './services/club-api.service';
import { IMemberApiService } from './services/member-api.service';
import { FederationApiService } from './services/impl/federation-api.service';
import { AuthApiService } from './services/impl/auth-api.service';
import { ClubApiService } from './services/impl/club-api.service';
import { MemberApiService } from './services/impl/member-api.service';
import { IPasswordApiService } from './services/password-api.service';
import { PasswordApiService } from './services/impl/password-api.service';
import { identifier, IHockeyFoodSsoApiService } from './services/hockeyfood-sso.service';
import { HockeyClubSsoApiService } from './services/impl/hockeyfood-sso.service';

export class LoginModule {
  constructor() {
    this.addDependencies();
  }

  private addDependencies(): void {
    container.addTransient<IFederationApiService>(FederationApiService);
    container.addTransient<IAuthApiService>(AuthApiService);
    container.addTransient<IClubApiService>(ClubApiService);
    container.addTransient<IMemberApiService>(MemberApiService);
    container.addTransient<IPasswordApiService>(PasswordApiService);
    container.addTransient<IHockeyFoodSsoApiService>(HockeyClubSsoApiService, identifier);
  }
}