import { RouteConfig } from 'vue-router';

const trainingRoutes: RouteConfig[] = [
  {
    name: 'TrainingScheme',
    path: '/trainingschema',
    component: () => import('../pages/TrainingScheduler.vue')
  },
]

export default trainingRoutes;