import { RouteConfig } from 'vue-router';

const organizationRoutes: RouteConfig[] = [
  {
    name: 'Organization',
    path: '/organisatie',
    component: () => import('../pages/Organization.vue')
  }
]

export default organizationRoutes;