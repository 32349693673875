import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { Organization } from '../../models/organization.model';
import { IOrganizationApiService } from '../organization-api.service';

export class OrganizationApiService extends BaseApiService implements IOrganizationApiService {
  private readonly organizationRoutes = {
    organization: 'organization'
  }

  async getOrganization(clubId: string): Promise<Organization> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.organizationRoutes.organization}`;
    const response = await axios.get(url);
    return response.data;
  }

}