import { inject } from "inversify-props";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ContractDetails } from "../models/contract-details.model";
import { Contract } from "../models/contract.model";
import { SelectListItem } from "../models/select-list-item.model";
import { IContractsApiService, identifier } from "../services/contracts.service";

@Module
export default class ContractsStoreModule extends VuexModule {

    private contracts: Contract[] = [];
    private contract: ContractDetails | null = null;
    private hourTypes: SelectListItem[] = [];
    private memberTeams: SelectListItem[] = [];

    @inject(identifier)
    private contractsApiService!: IContractsApiService;


    @Action
    public async loadContracts(payload: { showExpired: boolean }) : Promise<void> {
        const data = await this.contractsApiService.getContractList(this.context.rootGetters.currentClub.id, payload.showExpired);
        this.context.commit('setContracts', data.contracts);
    }

    @Action
    public async loadContractDetails(payload: { id: string }) : Promise<void> {
        const data = await this.contractsApiService.getContractDetails(this.context.rootGetters.currentClub.id, payload.id);
        this.context.commit('setContractDetails', data.contract);
    }

    @Action
    public resetContractDetails() : void {
        this.context.commit('setContractDetails', null);
    } 

    @Action 
    public async loadHourTypes() : Promise<void> {
        const data = await this.contractsApiService.getHourTypes(this.context.rootGetters.currentClub.id);
        this.context.commit('setHourTypes', data);
    }

    @Action
    public async loadMemberTeams(date: string | null = null) : Promise<void> {
        const data = await this.contractsApiService.getMemberTeams(this.context.rootGetters.currentClub.id, date);
        this.context.commit('setMemberTeams', data);
    }


    @Mutation
    public setContracts(contracts: Contract[]) {
        this.contracts = contracts;
    }

    @Mutation
    public setContractDetails(details: ContractDetails | null) {
        this.contract = details;
    }

    @Mutation
    public setHourTypes(data: SelectListItem[]) {
        this.hourTypes = data;
    }

    @Mutation
    public setMemberTeams(data: SelectListItem[]) {
        this.memberTeams = data;
    }

    get contractList() : Contract[] {
        return this.contracts;
    }

    get contractDetails(): Contract | null {
        return this.contract;
    }

    get clubId(): string {
        return this.context.rootGetters.currentClub.id;
    }

    get hourTypeList(): SelectListItem[] {
        return this.hourTypes;
    }

    get memberTeamsList() : SelectListItem[] {
        return this.memberTeams;
    }
}