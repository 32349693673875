














import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { GenerateHelper } from '../../shared/helpers/generate.helper';

import * as $ from 'jquery';

import 'clockpicker/dist/bootstrap-clockpicker.min.js';
import 'clockpicker/dist/bootstrap-clockpicker.min.css';

@Component
export default class ClockPicker extends Vue {

    @Prop() initValue!: string;
    @Prop() errorMsg!: string | null;

    isValid = true;
    id: string | null = null;

    beforeMount() {
        this.id = GenerateHelper.newGuid();
    }

    async mounted() {        

        setTimeout(() => {

            const self = this;

            $(document).ready(function() {           

                $(`#${self.id}`).clockpicker({                    
                    autoclose: true,
                    afterDone: function() {
                        const val = $(`#${self.id}`).val();                        
                        self.$emit('onChange', val);
                    }
                }).on;
            });

        }, 1);
    }

    private outFocus() {
        const self = this;
        const val = $(`#${self.id}`).val();
        self.$emit('onChange', val);
    }
    

    @Watch('errorMsg')
    onErrorMsgChange() {
        this.isValid = this.errorMsg === null || this.errorMsg === '';
    }
}
