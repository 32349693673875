import { inject } from 'inversify-props';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { RefereeMatch } from '../models/referee-match.model';
import { RefereeSummary } from '../models/referee-summary.model';
import { RefereeTeamMatch } from '../models/referee-team-match.model';
import { RefereeType } from '../models/referee-type.model';
import { TeamReferee } from '../models/team-referee.model';
import { IRefereeApiService } from '../services/referee-api.service';
import dayjs from 'dayjs';

@Module
export default class RefereeStoreModule extends VuexModule {
  @inject()
  private refereeApiService!: IRefereeApiService;

  private summary: RefereeSummary | null = null;
  private matches: RefereeMatch[] = [];
  private teamRefereeMatches: RefereeTeamMatch[] = [];
  private types: RefereeType[] = [];
  private referees: TeamReferee[] = [];
  private confirmMatch: RefereeMatch | null = null;

  private refereeAllMatches = {
    moduleLoading: false,
    selectedDate: dayjs().format('YYYY-MM-DD'),
    reloadStatistics: false
  };

  @Action
  public async loadRefereeSummary(): Promise<void> {
    const data = await this.refereeApiService.getSummary(this.context.rootGetters.currentClub.id);
    this.context.commit('setRefereeSummary', data);
  }
  @Action
  public async loadRefereeMatches(showHistory: boolean): Promise<void> {
    const data = await this.refereeApiService.getRefereeMatches(this.context.rootGetters.currentClub.id, showHistory);
    this.context.commit('setRefereeMatches', data);
  }
  @Action
  public async loadRefereeTeamMatches(showHistory: boolean): Promise<void> {
    const data = await this.refereeApiService.getRefereeTeamMatches(this.context.rootGetters.currentClub.id, showHistory);
    this.context.commit('setRefereeTeamMatches', data);
  }
  @Action
  public async refereeUnplanMatch(matchId: string): Promise<void> {
    const data = await this.refereeApiService.unplanRefereeMatch(this.context.rootGetters.currentClub.id, matchId);
    if (!data) {
      throw new Error('Failed to unplan match');
    }
  }
  @Action
  public async loadRefereeTypes(): Promise<void> {
    const data = await this.refereeApiService.getRefereeTypes(this.context.rootGetters.currentClub.id);
    this.context.commit('setRefereeTypes', data);
  }
  @Action
  public async assignReferees(payload: { matchId: string, refereeTypeId: string, refereesIds: string[]}): Promise<void> {
    const data = await this.refereeApiService.assignRefereesToMatch(this.context.rootGetters.currentClub.id,
      payload.matchId, payload.refereeTypeId, payload.refereesIds
    );
    if (!data) {
      throw new Error('Failed to assign referees');
    }
  }
  @Action
  public async loadTeamReferees(matchId: string) {
    const data = await this.refereeApiService.getRefereesForTeam(this.context.rootGetters.currentClub.id, matchId);
    this.context.commit('setTeamReferees', data);
  }    
  @Action
  public async loadConfirmMatch(payload: { token: string }): Promise<void> {
    const data = await this.refereeApiService.getMatchToConfirmReferee(payload.token);
    this.context.commit('setMatch', data);
  }
  @Action
  public async confirmReferee(payload: { token: string }): Promise<void> {
    await this.refereeApiService.confirmReferee(payload.token);
  }

  @Mutation
  public setRefereeSummary(summary: RefereeSummary): void {
    this.summary = summary;
  }
  @Mutation
  public setRefereeMatches(matches: RefereeMatch[]): void {
    this.matches = matches;
  }
  @Mutation
  public setRefereeTeamMatches(matches: any): void {
    this.teamRefereeMatches = matches;
  }
  @Mutation
  public setRefereeTypes(types: any): void {
    this.types = types;
  }
  @Mutation
  public setTeamReferees(referees: any): void {
    this.referees = referees;
  }    
  @Mutation
  public setMatch(confirmMatch: RefereeMatch) {
    this.confirmMatch = confirmMatch;
  }
  @Mutation
  public setAllMatchesLoading(value: boolean) {
    this.refereeAllMatches.moduleLoading = value;
  }
  @Mutation
  public setAllMatchesDate(value: string) {
    this.refereeAllMatches.selectedDate = value;
  }

  get refereeSummary() {
    return this.summary;
  }

  get refereeMatches() {
    return this.matches;
  }

  get refereeTeamMatches() {
    return this.teamRefereeMatches;
  }

  get refereeTypes() {
    return this.types;
  }

  get teamReferees() {
    return this.referees;
  }

  get confirmMatchInfo(): RefereeMatch | null {
      return this.confirmMatch;
  }

  get allMatchesLoading() : boolean {
    return this.refereeAllMatches.moduleLoading;
  }

  get allMatchesDate() : string {
    return this.refereeAllMatches.selectedDate;
  }
  
  get allMatchesReloadStatistics() : boolean {
    return this.refereeAllMatches.reloadStatistics;
  }
}