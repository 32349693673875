var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        class: _vm.btnClass,
        attrs: { disabled: _vm.isLoading },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.click.apply(null, arguments)
          },
        },
      },
      [
        _vm.isLoading
          ? _c("b-spinner", { attrs: { small: "", label: "Spinning" } })
          : _vm._e(),
        _vm._v("  "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }