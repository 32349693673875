import { Club } from '@/modules/login/models/club.model';
import { Member } from '@/modules/login/models/member.model';

export class ModuleHelper {
  public static hasModule(module: string, club: Club): boolean {
    return module ? club?.modules?.some(x => x === module) : true;
  }

  public static hasRole(role: string, member: Member): boolean {
    return role ? member?.customRoles?.some(x => x === role) : true;
  }
}