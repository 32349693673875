import { container } from "inversify-props";
import { identifier, IContractsApiService } from "./services/contracts.service";
import { ContractsApiService } from "./services/impl/contracts.service";
import TrainingsApiService from "./services/impl/trainings.service";
import { ITrainingsApiService } from "./services/trainings.service";

export class ContractsModule {
    constructor() {
        this.addDependencies();
    }

    private addDependencies() {
        container.addTransient<IContractsApiService>(ContractsApiService, identifier);
        container.addTransient<ITrainingsApiService>(TrainingsApiService)
    }
}

