import { container } from "inversify-props";
import { identifier, IDocumentApiService } from "./services/doument.service";
import { DocumentApiService } from "./services/impl/document.service";

export class DocumentModule {
    constructor() {
        this.addDependencies();
    }

    private addDependencies() {
        container.addTransient<IDocumentApiService>(DocumentApiService, identifier);
    }
}