var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "confirm-delete-modal",
      attrs: { id: "confirm-delete-modal", title: _vm.modalTitle },
      on: { ok: _vm.handleOk },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function (ref) {
            var ok = ref.ok
            var cancel = ref.cancel
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: _vm.modalCancelButtonVariant },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.modalCancelButtonText) + " ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: _vm.modalConfirmButtonVariant },
                  on: {
                    click: function ($event) {
                      return ok()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.modalConfirmButtonText) + " ")]
              ),
            ]
          },
        },
      ]),
    },
    [_c("p", [_vm._v(_vm._s(_vm.modalMessage))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }