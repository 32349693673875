import { injectable } from 'inversify-props';
import { IAuthApiService } from '../auth-api.service';
import { BaseApiService } from '../../../../shared/services/base-api.service';
import { LoginCredentials } from '../../models/login-credentials.model';
import axios from 'axios';
import { AuthToken } from '../../models/auth-token.model';

@injectable()
export class AuthApiService extends BaseApiService implements IAuthApiService {
  private readonly authRoutes = {
    authTokens: 'auth_tokens',
    passwordReset: 'password_reset'
  }

  async logout(clubId: string, token: string): Promise<void> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.authRoutes.authTokens}/${token}`;
    await axios.delete(url);
  }
  async resetPassword(clubId: string, federationMembershipNumer: string, clubMembershipNumber: string): Promise<void> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.authRoutes.passwordReset}`;
    return;
  }

  async login(clubId: string, credentials: LoginCredentials): Promise<AuthToken | null> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.authRoutes.authTokens}`;
    const response = await axios.post<AuthToken>(url, credentials)
    if (response.status !== 201) {
      return null;
    }
    return response.data;
  }

}