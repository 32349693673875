import { Duty as DutyModel } from '../models/duty.model';
import { DutyMemberAcceptance } from '../models/duty_member_acceptance.model';
import { DutyReminderAcceptance } from '../models/duty_reminder_acceptance.model';
import { DutySeasonPoints } from '../models/duty_season_points.model';
import { MyDutyRoleItemPaged } from '../models/my-dutyroleitem-paged.model';

export interface IDutyApiService {
  getDuties(clubId: string, start: Date, end: Date): Promise<DutyModel[]>
  getMyAssignedDuties(clubId: string, showOld: boolean, pageNumber: number, pageSize: number) : Promise<MyDutyRoleItemPaged>
  assignToDuty(clubId: string, dutyId: string, roleId: string, occuranceId: string): Promise<boolean>
  unAssignFromDuty(clubId: string, dutyId: string, assignmentId: string): Promise<boolean>
  getDutyReminderToAssign(token: string) : Promise<DutyReminderAcceptance>
  assignToDutyAnonymous(token: string) : Promise<boolean>
  getDutyReminderFromToken(clubId: string, token: string) : Promise<DutyMemberAcceptance>
  getDutySeasonPoints(clubId: string) : Promise<DutySeasonPoints>
}

export const identifier = 'DUTIES_DUTY_API_SERVICE';