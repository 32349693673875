import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { PresenceStatus } from '../../../../shared/enums/presence-status.enum';
import { TrainingDetails } from '../../models/training-details.model';
import { ITrainingApiService } from '../training-api.service';

@injectable()
export class TrainingApiService extends BaseApiService implements ITrainingApiService {
  private readonly trainingRoutes = {
    trainings: 'trainings',
    occurence: 'occurences',
    teams: 'teams',
    members: 'members',
    presence: 'presences'
  }

  async changeTrainingPresenceStatus(clubId: string, trainingId: string, teamId: string, memberId: string, status: PresenceStatus): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.trainingRoutes.trainings}/${trainingId}/${this.trainingRoutes.teams}/${teamId}/${this.trainingRoutes.members}/${memberId}/${this.trainingRoutes.presence}`;
    const response = await axios.post(url, { status });
    return response.status === 200;
  }

  async getTrainingsDetails(clubId: string, trainingId: string, occurenceId: string, teamId: string) : Promise<TrainingDetails> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.trainingRoutes.trainings}/${trainingId}/${this.trainingRoutes.occurence}/${occurenceId}/${this.trainingRoutes.teams}/${teamId}`;
    const response = await axios.get(url);

    return response.data;
  }
}