import { RouteConfig } from 'vue-router';

const documentRoutes: RouteConfig[] = [
  {
    name: 'Documents',
    path: '/documenten',
    component: () => import('../pages/Documents.vue')
  }
];

export default documentRoutes;