import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { TrainingPresence } from '../models/training-presence.model';
import { Training } from '../models/training.model';
import { TrainingsPaged } from '../models/trainings-paged.model';

export interface ITeamTrainingApiService {
  getTeamTrainings(clubId: string, teamId: string, showHistory: boolean, currentPage: number, pageSize: number): Promise<TrainingsPaged>
  changePresenceStatus(clubId: string, teamId: string, trainingId: string, memberId: string, status: PresenceStatus): Promise<boolean>
  getTrainingPresence(clubId: string, teamId: string, trainingId: string, occurenceId: string): Promise<TrainingPresence>;
}

export const identifier = 'TEAMS_TEAM_API_SERVICE';