
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

@Module
export default class UiStateModule extends VuexModule {
  private isLoading = true;
  private showAll = false;

  @Action
  public async setLoading(loading: boolean): Promise<void> {
    this.context.commit('loadSet', loading);
    }

  @Action
  public async setShawAllMenu(show: boolean): Promise<void> {
    this.context.commit('showAllSet', show);
  }

  @Mutation
  public loadSet(loading: boolean): void {
    this.isLoading = loading;
    }

  @Mutation
  public showAllSet(value: boolean): void {
    this.showAll = value;
  }

  get isAppLoading(): boolean {
    return this.isLoading
  }

  get showAllMenu(): boolean {
    return this.showAll;
  }
}