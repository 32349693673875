import dayjs from "dayjs";

export class DateHelper {
  static getDate(input: string | Date) {
    const date = new Date(input);

    const dd = date.getDate()
    const mm = date.getMonth() + 1;
    const day = dd < 10 ? `0${dd}` : dd;
    const month = mm < 10 ? `0${mm}` : mm;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  static getTime(input: string | Date) {
    const date = new Date(input);

    const hh = date.getHours();
    const mm = date.getMinutes();
    const hours = hh < 10 ? `0${hh}` : hh;
    const minutes = mm < 10 ? `0${mm}` : mm;
    return `${hours}:${minutes}`;
  }

  static parseDate(input: string | Date, format: string | null = null) {
    format = format === null ? 'D-M-YYYY' : format;
    return dayjs(input).format(format);
  }

  static parseTime(input: string | Date, format: string | null = null) {
    format = format === null ? 'HH:mm' : format;
    return dayjs(input).format(format);
  }

  static toSeconds(input: string) : number {
    const splitted = input.split(':');
    let seconds = 0;

    if (splitted.length <= 1) {
      return seconds;
    }

    seconds += (+splitted[0]) * 60 * 60; // hours -> seconds
    seconds += (+splitted[1]) * 60;      // minutes -> seconds

    if (splitted.length > 2) {
      seconds += (+splitted[2]);
    }

    return seconds;
  }
}