import { FillSurveyResponse } from "../models/fill-survey-response.model";
import { SurveyQuestionGroup } from "../models/survey-question-group.model";

export interface ISurveyApiService {
    getFillSurvey(token: string, eventId?: string | null, eventToken?: string | null) : Promise<FillSurveyResponse>;
    startSurvey(clubId: string, surveyId: string, personId: string, respondentId: string, eventId: string | null, eventToken: string | null) : Promise<SurveyQuestionGroup>;
    resumeSurvey(clubId: string, surveyId: string, personId: string, respondentId: string, eventId: string | null, eventToken: string | null) : Promise<SurveyQuestionGroup>;
    nextQuestionGroup(clubId: string, surveyId: string, answears: SurveyQuestionGroup) : Promise<SurveyQuestionGroup>;
    peviousQuestionGroup(clubId: string, surveyId: string, personId: string, respondentId: string, order: number, eventId: string | null, eventToken: string | null) : Promise<SurveyQuestionGroup>;
    finnishSurvey(clubId: string, surveyId: string, model: SurveyQuestionGroup) : Promise<void>
}

export const identifier = 'SURVEYS_API_SERVICE';