import { BaseApiService } from "@/shared/services/base-api.service";
import { injectable } from "inversify-props";
import { SeasonSelectItemResponse } from "../../models/season-select-item.model";
import { IInvoiceApiService } from "../invoices.service";
import axios from "axios";
import { InvoiceResponse } from "../../models/invoice.model";

@injectable()
export class InvoiceApiService extends BaseApiService implements IInvoiceApiService {

    private readonly invoicesRoutes = {
        invoices: "invoices",
        download: "download"
    };

    private readonly selectsRoutes = {
        selects: "selects",
        seasons: "seasons"
    };

    async getSeasons(clubId: string): Promise<SeasonSelectItemResponse[]> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.selectsRoutes.selects}/${this.selectsRoutes.seasons}`;
        const response = await axios.get(url);

        return response.data;
    }

    async getInvoices(clubId: string, seasonId: string) : Promise<InvoiceResponse> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.invoicesRoutes.invoices}`;
        const response = await axios.get(url, { params: { seasonId } });

        return response.data;
    }

    async downloadInvoice(clubId: string, invoiceId: number, fileId: number) : Promise<any> {
        
        return new Promise( (resolve, reject) => {
            const memberToken = JSON.parse(localStorage.getItem('currentUser')!);
            
            const xhr = new XMLHttpRequest();

            xhr.open("GET", `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.invoicesRoutes.invoices}/${this.invoicesRoutes.download}/${invoiceId}/${fileId}`);

            xhr.responseType = "arraybuffer";

            xhr.setRequestHeader('x-lisa-auth-token', memberToken?.token || '');
            xhr.setRequestHeader('Authorization', process.env?.VUE_APP_API_AUTHORIZATION || '');

            xhr.onload = function () {
                if (this.status === 200) {
                    const fileName = this.getResponseHeader('x-lisa-file-name') || '';

                    resolve({ data: new Blob([this.response]), fileName: fileName});
                }
                else {
                    reject();
                }
            }

            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }

            xhr.send();
        });
    }
}