import { RouteConfig } from 'vue-router';

const dutiesRoutes: RouteConfig[] = [
  {
    name: 'Duties',
    path: '/diensten',
    component: () => import('../pages/Duties.vue')
  },
  {
    path: '/dutyreminder/:token?',
    name: 'DutyReminder',
    component: () => import('../pages/DutyReminder.vue'),
    props: true,
    meta: {
      allowAnonymous: true
    }
  }
]

export default dutiesRoutes;