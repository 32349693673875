import { container } from 'inversify-props';
import { RefereeApiService } from './services/impl/referee-api.service';
import { IRefereeApiService } from './services/referee-api.service';

export class RefereeModule {
  constructor() {
    this.addDependencies();
  }

  private addDependencies(): void {
    container.addTransient<IRefereeApiService>(RefereeApiService);
  }
}