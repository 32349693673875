import { container } from "inversify-props";
import { TrainingScheduleApiService } from "./services/impl/trainingScheduler.service";
import { identifier, ITrainingScheduleApiService } from "./services/trainingScheduler.service";

export class TrainingScheduleModule {

    constructor () {
        this.addDependencies();
    }

    private addDependencies () {
        container.addTransient<ITrainingScheduleApiService>(TrainingScheduleApiService, identifier);
    }
}