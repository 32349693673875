import { container } from 'inversify-props';
import { IDashboardApiService } from './services/dashboard-api.service';
import { DashboardApiService } from './services/impl/dashboard-api.service';
import { IDutyApiService } from './services/duty-api.service';
import { DutyApiService } from './services/impl/duty-api.service';
import { TeamsApiService } from './services/impl/teams-api.service';
import { ITeamsApiService } from './services/teams-api.service';
import { IMatchApiService } from './services/match-api.service';
import { MatchApiService } from './services/impl/match-api.service';
import { ITrainingApiService } from './services/training-api.service';
import { TrainingApiService } from './services/impl/training-api.service';

export class DashboardModule {
  constructor() {
    this.addDependencies();
  }

  private addDependencies() {
    container.addTransient<ITeamsApiService>(TeamsApiService);
    container.addTransient<IDashboardApiService>(DashboardApiService);
    container.addTransient<IDutyApiService>(DutyApiService);
    container.addTransient<IMatchApiService>(MatchApiService);
    container.addTransient<ITrainingApiService>(TrainingApiService);
  }
}