import { BvToast } from 'bootstrap-vue';
import { LocaleMessage } from 'vue-i18n';

export class ToastHelper {
  public static showSuccess(toaster: BvToast, message: string | LocaleMessage): void {
    toaster.toast(message.toString(), {
      title: 'Succes',
      autoHideDelay: 3000,
      appendToast: true,
      toaster: 'b-toaster-top-center',
      variant: 'success',
      static: true
    })
  }

  public static showError(toaster: BvToast, message: string | LocaleMessage): void {
    toaster.toast(message.toString(), {
      title: 'Error',
      autoHideDelay: 3000,
      appendToast: true,
      toaster: 'b-toaster-top-center',
      variant: 'danger',
      static: true
    })

  }
}