import { injectable } from 'inversify-props';
import axios  from 'axios';
import { IFederationApiService } from '../federation-api.service';
import { Federation } from '../../models/federation.model';
import { BaseApiService } from '../../../../shared/services/base-api.service';

@injectable()
export class FederationApiService extends BaseApiService implements IFederationApiService {
  private readonly federationUrl = `${this.BASE_URL}/${this.routes.federations}`

  async getFederationByCurrentDomain(): Promise<Federation | null> {
    const domain = process.env.VUE_APP_TEMP_DOMAIN ?? window.location.hostname;
    const response = await axios.get<Federation | null>(this.federationUrl, {params: {
      domain: domain
    }});
    return Promise.resolve(response.data);
  }
}