import { MenuItem } from '../models/menu-item.model';

export const personalMenuData: MenuItem[] = [
  // Temporary disabled by LX-8235
  // {
  //   path: '/gezin',
  //   text: 'Gezin',
  //   icon: 'far fa-fw fa-users mr-3',
  //   modules: [],
  //   roles: [],
  // },
  {
    path: '/facturen',
    text: 'Facturen',
    icon: 'far fa-fw fa-file-invoice mr-3',
    modules: ['Finances_LightInvocing', 'Finances_LisaContributie'],
    onlySuperUser: true,
    roles: [],
  },
  //Temporary disabled by LX-8235
  // {
  //   path: '/materialen',
  //   text: 'Materialen',
  //   icon: 'far fa-fw fa-traffic-cone mr-3',
  //   modules: ['Materials'],
  //   roles: []
  // },
]