














































































import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Match } from '../../models/match.model';
import { Team } from '../../models/team.model';
import TeamMatchPresenceModal from './TeamMatchPresenceModal.vue';

@Component
export default class TeamMatches extends Vue { 
  showHistorical = false;
  
  changePresenceClicked = false;

  isLoading = false;
  fields: any[] =  [ 
    { key: 'date', label: this.$t('teams.matches.date'), tdClass: 'date date-no-wrap vertical-align-middle' },
    { key: 'opponent', label: this.$t('teams.matches.opponent'), tdClass: 'vertical-align-middle' },
    { key: 'time', label: this.$t('teams.matches.time'), tdClass: 'vertical-align-middle' },
    { key: 'locationName', label: this.$t('teams.matches.location'), tdClass: 'vertical-align-middle' },
    { key: 'pitchName', label: this.$t('teams.matches.field'), tdClass: 'vertical-align-middle' },
    { key: 'refereesName', label: this.$t('teams.matches.referees'), tdClass: 'vertical-align-middle' },
    { key: 'attendance', label: this.$t('teams.matches.attendance'), tdClass: 'vertical-align-middle' },
    { key: 'currentUserPresence', label: '', tdClass: 'current-user-attendance vertical-align-middle' }, ]

  reactionPresent: PresenceStatus = PresenceStatus.Present;
  reactionUnknown: PresenceStatus = PresenceStatus.Unknown;
  reactionAbsent: PresenceStatus = PresenceStatus.Absent;
  
  changingPresencesRows: string[] = [];
  selectedDropdown: string | null = null;

  @Action
  loadMatches!: (payload: { teamId: string, showHistoricalMatches: boolean }) => Promise<void>
  @Action
  setLoading!: (loading: boolean) => Promise<void>
  @Action
  changeMatchPresenceStatus!: (payload: { clubId: string, matchId: string, teamId: string, status: PresenceStatus }) => Promise<void>;
  
  @Getter
  teamMatches!: Match[];
  @Getter
  teamInfo!: Team;

  async created(): Promise<void> {
    await this.reloadMatches();    
  }

  async reloadMatches(): Promise<void> {
    try {
      this.changingPresencesRows = [];
      this.isLoading = true;
      await this.loadMatches( { teamId: this.$route.params.id, showHistoricalMatches: this.showHistoricalMatches });
    } 
    catch  {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.'); 
    } finally {
      this.isLoading = false;
    }
  }

  async filterChanged(): Promise<void> {
    this.isLoading = true;
    await this.loadMatches({ teamId: this.$route.params.id, showHistoricalMatches: this.showHistoricalMatches });
    this.isLoading = false;
  }
  
  toggleShowDropdown(id: string) : void {
    if (!this.selectedDropdown || this.selectedDropdown !== id) {
      this.selectedDropdown = id;
      return;
    }
    
    this.selectedDropdown = null;
  }

  showDropdown(id: string) : boolean {
    if (!this.showDropdown) {
      return false;
    }

    return this.selectedDropdown === id;
  }

  getOpponent(match: Match) : string {
    let result = '';

    if (!match.opponent) {
      return result;
    }

    result = match.opponent.name;

    if (match.opponent.clubName) {
      result += ` (${match.opponent.clubName})`;
    }

    return result;
  }

  openEditPresenceModal(matchId: string): void {
    this.$modal.show(TeamMatchPresenceModal, {
      matchId: matchId,
      clubId: this.teamInfo.clubId
    }, { }, { })
  }

  get showHistoricalMatches(): boolean {
    return this.showHistorical;
  }
  
  set showHistoricalMatches(value: boolean) {
    this.showHistorical = value;
    this.filterChanged();
  }

  async changePresenceStatus(matchId: string, status: PresenceStatus): Promise<void> {
    try {
      this.changingPresencesRows = [];
      if (this.changePresenceClicked) 
        return;

      if (this.teamMatches.find(x => x.id === matchId)?.currentUserPresence === status)
        return;

      this.changingPresencesRows.push(matchId);
      this.changePresenceClicked = true;

      const payload = {
        clubId: this.teamInfo.clubId,
        matchId: matchId,
        teamId: this.$route.params.id,
        status: status
      }

      await this.changeMatchPresenceStatus(payload);

      this.updateMatchesStorage(matchId, status);

      ToastHelper.showSuccess(this.$bvToast, `${this.$t('shared.presenceChanged')}`);
    } 
    catch  {
      ToastHelper.showError(this.$bvToast,  `${this.$t('shared.errorMessage')}`);
    } 
    finally {
      this.changingPresencesRows = this.changingPresencesRows.filter(x => x !== matchId);
      this.changePresenceClicked = false;
      this.toggleShowDropdown(matchId);
    }
  }

  private updateMatchesStorage(matchId: string, status: PresenceStatus): void {    
     this.teamMatches.forEach((element, index) => {
        if (element.id === matchId) {           
              if (status === PresenceStatus.Present) {
                this.teamMatches[index].myTeamPresentPersonsCount++;
              }
              if (status !== PresenceStatus.Present && this.teamMatches[index].currentUserPresence === PresenceStatus.Present) {
                this.teamMatches[index].myTeamPresentPersonsCount--;
              }
              
              this.teamMatches[index].currentUserPresence = status;
          }         
      });

      this.$store.commit('setTeamMatches', this.teamMatches);
  }
}
