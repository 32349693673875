import { RouteConfig } from 'vue-router'; 

const loginRoutes: RouteConfig[] = [
  { 
    path: '/login/:clubId?', 
    name: 'Login', 
    component: () => import('../pages/LoginPage.vue'),
    props: true,
    meta: {
      anonymousOnly: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../pages/ResetPassword.vue'),
    meta: {
      anonymousOnly: true
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ConfirmResetPassword',
    component: () => import('../pages/ConfirmResetPassword.vue'),
    props: true,
    meta: {
      anonymousOnly: true
    }
  }
]

export default loginRoutes;