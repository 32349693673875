import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { TokenStatus } from '../../models/token-status.model';
import { IPasswordApiService } from '../password-api.service';
import { injectable } from 'inversify-props';

@injectable()
export class PasswordApiService extends BaseApiService implements IPasswordApiService {
  private readonly passwordRoutes = {
    clubs: 'clubs',
    resetPassword: 'reset_password',
    confirm: 'confirm',
    resendResetPassword: 'resend_reset_password'
  }

  async resetPassword(clubId: string, federationMembershipNumber: string | null, clubMembershipNumber: string | null, emailAddress: string): Promise<void> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.passwordRoutes.resetPassword}`
    await axios.post(url, { federationMembershipNumber, clubMembershipNumber, emailAddress })
  }

  async confirmResetPassword(token: string, password: string): Promise<number> {
    const url = `${this.BASE_URL}/${this.passwordRoutes.resetPassword}/${this.passwordRoutes.confirm}`;
    const response = await axios.post(url, { token, password })
    return response.status
  }

  async resendResetPasswordToken(clubId: string, token: string) : Promise<void> {
    const url = `${this.BASE_URL}/clubs/${clubId}/${this.passwordRoutes.resendResetPassword}/${token}`;
    await axios.post(url);
  }

  async validateTokenStatus(token: string): Promise<TokenStatus|null> {
    const url = `${this.BASE_URL}/${this.passwordRoutes.resetPassword}/${token}`;
    const response = await axios.get<TokenStatus|null>(url);
    return response.data;
  }

}