import { BaseApiService } from "@/shared/services/base-api.service";
import { ITrainingScheduleApiService } from "../trainingScheduler.service";
import axios from "axios";
import { TrainingSchemaResponse } from "../../models/trainings-for-scheduler.model";
import { injectable } from "inversify-props";
import { TeamsAndDates } from "../../models/teams-and-dates.model";

@injectable()
export class TrainingScheduleApiService extends BaseApiService implements ITrainingScheduleApiService {

    private readonly trainingRoutes = {
        training_schdeule: 'training-schedule',
        teams_and_dates: 'teams-and-dates'
    };

    async getTrainings(clubId: string, from?: string | null, to?: string | null, teamsIds?: string[] | null): Promise<TrainingSchemaResponse> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.trainingRoutes.training_schdeule}`;

        const response = await axios.post(url, {
            startDate: from,
            endDate: to,
            teamsIds: teamsIds
        });

        return response.data;
    }

    
    async getTeamsAndDates(clubId: string): Promise<TeamsAndDates> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.trainingRoutes.training_schdeule}/${this.trainingRoutes.teams_and_dates}`;
        const response = await axios.get(url);

        return response.data;
    }

}