import { BaseApiService } from "@/shared/services/base-api.service";
import axios from "axios";
import { injectable } from "inversify-props";
import { Module } from "vuex-module-decorators";
import { ContractTrainingWrapped } from "../../models/contact-training-wrapped.model";
import { ITrainingsApiService } from "../trainings.service";

@injectable()
export default class TrainingsApiService extends BaseApiService implements ITrainingsApiService {

    private readonly trainingRoutes = {
        trainings: 'trainings'
    }

    async getTrainings(clubId: string, 
        pageNumber: number, 
        pageSize: number, 
        showBookedTrainings: 
        boolean, 
        contractId: string,
        dateFrom: string | null,
        dateTo: string | null) : Promise<ContractTrainingWrapped> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.trainingRoutes.trainings}`;
        const params = {
            pageNumber,
            pageSize,
            showBookedTrainings,
            contractId,
            dateFrom,
            dateTo
        };
        const response = await axios.get(url, { params });

        return response.data;
    }    
} 