import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import MatchCalendarStatistics from '../../models/match-calendar-statistics.model';
import { RefereeMatch } from '../../models/referee-match.model';
import { RefereeMatchesAll } from '../../models/referee-matches-all.model';
import { RefereeSummary } from '../../models/referee-summary.model';
import { RefereeTeamMatch } from '../../models/referee-team-match.model';
import { RefereeType } from '../../models/referee-type.model';
import { TeamReferee } from '../../models/team-referee.model';
import { IRefereeApiService } from '../referee-api.service';

@injectable()
export class RefereeApiService extends BaseApiService implements IRefereeApiService {
  private readonly refereeRoutes = {
    referees: 'referees',
    summary: 'summary',
    matches: 'matches',
    confirm: 'confirm',
    refereeTypes: 'referee_types',
    team: 'team',
    calendar: 'calendar',
    all: 'all',
    selfAssign: 'self-assign'
  }

  async getSummary(clubId: string): Promise<RefereeSummary> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.summary}`;
    const response = await axios.get(url);
    return response.data.summary;
  }

  async getRefereeMatches(clubId: string, showHistory: boolean): Promise<RefereeMatch[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}?showHistory=${showHistory}`
    const response = await axios.get(url);
    return response.data.matches;
  }

  async unplanRefereeMatch(clubId: string, matchId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}/${matchId}`;
    const response = await axios.delete(url);
    return response.status === 200;
  }

  async getRefereeTeamMatches(clubId: string, showHistory: boolean): Promise<RefereeTeamMatch[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.team}/${this.refereeRoutes.matches}?showHistory=${showHistory}`
    const response = await axios.get(url);
    return response.data.matches;
  }

  async assignRefereesToMatch(clubId: string, matchId: string, refereeTypeId: string, refereesIds: string[]): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}/${matchId}`;
    const response = await axios.put(url, { refereeTypeId, refereesIds });
    return response.status === 200;
  }

  async getRefereeTypes(clubId: string): Promise<RefereeType[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.refereeTypes}`;
    const response = await axios.get(url);
    return response.data.types;
  }

  async getRefereesForTeam(clubId: string, matchId: string): Promise<TeamReferee[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}?matchId=${matchId}`;
    const response = await axios.get(url);
    return response.data.referees;
  }

  async getMatchToConfirmReferee(token: string): Promise<RefereeMatch> {
    const url = `${this.BASE_URL}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}/${token}`;
    const response = await axios.get(url);
    return response.data;
  }

  async confirmReferee(token: string): Promise<void> {
    const url = `${this.BASE_URL}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}/${this.refereeRoutes.confirm}/${token}`;
    const response = await axios.post(url);
    return response.data;
  }

  
  async getAllMatchesForReferee(clubId: string, date: string) : Promise<RefereeMatchesAll> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}/${this.refereeRoutes.all}?date=${date}`;
    const response = await axios.get(url);
    return response.data;
  }

  async getCalendarStatistics(clubId: string, year: number, month: number) : Promise<MatchCalendarStatistics> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}/${this.refereeRoutes.calendar}?year=${year}&month=${month}`;
    const response = await axios.get(url);
    return response.data;
  }

  async selfAssignReferee(clubId: string, matchId: string, refereeType: string) : Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.refereeRoutes.referees}/${this.refereeRoutes.matches}/${this.refereeRoutes.all}/${this.refereeRoutes.selfAssign}/${matchId}`;
    const response = await axios.post(url, { referee_type_id: refereeType });
    return response.status === 201;
  }
}