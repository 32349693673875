import { container } from "inversify-props";
import { SurveyApiService } from "./services/impl/surveys.service";
import { identifier, ISurveyApiService } from "./services/surveys.service";

export class SurveysModule {
    
    constructor () {
        this.addDependencies();
    }

    private addDependencies() {
        container.addTransient<ISurveyApiService>(SurveyApiService, identifier);
    }
}