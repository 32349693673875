import { BaseApiService } from '@/shared/services/base-api.service'
import axios from 'axios';
import { injectable } from 'inversify-props';
import { Match } from '../../models/match.model';
import { PresenceStatus } from '../../../../shared/enums/presence-status.enum';
import { IMatchApiService } from '../match-api.service';
import { MatchDetails } from '../../models/match-details.model';

@injectable()
export class MatchApiService extends BaseApiService implements IMatchApiService {
  private readonly matchesRoutes = {
    matches: 'matches',
    teams: 'teams',
    members: 'members',
    presence: 'presence',
    tile: 'tile'
  }

  async changeMatchPresenceStatus(clubId: string, matchId: string, teamId: string, memberId: string, status: PresenceStatus): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.matchesRoutes.matches}/${matchId}/${this.matchesRoutes.teams}/${teamId}/${this.matchesRoutes.members}/${memberId}/${this.matchesRoutes.presence}`;
    const response = await axios.post(url, { status });
    return response.status === 201;
  }

  async getMatchDetails(clubId: string, matchId: string): Promise<Match> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.matchesRoutes.matches}/${matchId}`;
    const response = await axios.get(url);
    return response.data;
  }

  async getMatchDetailsForTile(clubId: string, matchId: string) : Promise<MatchDetails> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.matchesRoutes.matches}/${matchId}/${this.matchesRoutes.tile}`;
    const response = await axios.get(url);
    return response.data;
  }
}