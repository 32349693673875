var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _vm.isFullscreenRoute
      ? _c(
          "div",
          { staticClass: "fullscreen" },
          [_c("router-view", { key: _vm.$route.path })],
          1
        )
      : _vm.currentMember
      ? _c(
          "div",
          [_c("Wrapper", [_c("router-view", { key: _vm.$route.path })], 1)],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }