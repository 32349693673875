var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-group datepicker-group" }, [
    _c("input", {
      staticClass: "form-control datepicker",
      class: [_vm.isValid ? "" : "invalid"],
      staticStyle: { "min-height": "40px" },
      attrs: { type: "text", id: _vm.id, disabled: _vm.disabled },
      domProps: { value: _vm.formattedDate },
    }),
    _vm._m(0),
    !_vm.isValid
      ? _c(
          "div",
          {
            staticClass: "invalid-feedback",
            staticStyle: { display: "block" },
          },
          [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("span", { staticClass: "fa fa-calendar" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }