import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class InvoicesStoreModule extends VuexModule {

    private selectedMembers: string[] = [];

    @Mutation
    public setTeamMatches(selectedMembers: string[]) {
        this.selectedMembers = selectedMembers;
    }

    get getSelectedMembers() : string[] {
        return this.selectedMembers;
    }
}