import { NewTaskType } from '../models/new-task-type.model';
import { TeamTaskMatchTask } from '../models/team-task-match-task.model';
import { TeamTaskMatch } from '../models/team-task-match.model';
import { TeamTaskMember } from '../models/team-task-member.model';
import { TeamTaskTableViewResponse } from '../models/team-task-table-view-response.model';
import { TeamTaskType } from '../models/team-task-type.model';

export interface ITeamTaskApiService {
  getTeamTaskMatches(clubId: string, teamId: string, showHistory: boolean): Promise<TeamTaskMatch[]>
  getTeamTaskTypes(clubId: string, teamId: string): Promise<TeamTaskType[]>
  getTeamTaskMembers(clubId: string, teamId: string): Promise<TeamTaskMember[]>
  deleteTaskAssignment(clubId: string, teamId: string, matchId: string, taskId: string): Promise<number>
  assignTaskToMember(clubId: string, teamId: string, matchId: string, teamPersonId: string, taskTypeId: string): Promise<{status: number, result: TeamTaskMatchTask}>
  createTaskType(clubId: string, teamId: string, taskType: NewTaskType): Promise<boolean>
  updateTaskType(clubId: string, teamId: string, taskId: string, taskType: NewTaskType): Promise<boolean>
  deleteTaskType(clubId: string, teamId: string, taskId: string): Promise<boolean>
  getTeamTaskTableView(clubId: string, teamId: string, showHistory: boolean): Promise<TeamTaskTableViewResponse>;
}

export const identifier = 'TEAM_TEAM_TASK_API_SERVICE';
