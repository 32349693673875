import { BaseApiService } from '../../../../shared/services/base-api.service';
import { IMemberApiService } from '../member-api.service';
import axios from 'axios';
import { Member } from '../../models/member.model';

export class MemberApiService extends BaseApiService implements IMemberApiService {
  private readonly memberRoutes = {
    members: 'members'
  }

  async getMember(clubId: string, memberId: string): Promise<Member> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.memberRoutes.members}/${memberId}`;
    const response = await axios.get(url);
    return response.data.clubMember;
  }
}