









import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class SpinnerButton extends Vue {
  isLoading = false;
  @Prop({ default: 'btn btn-primary btn-block' }) 
  btnClass!: string

  click(): void {
    this.isLoading = true;
    this.$emit('click');
  }

  release(): void {
    this.isLoading = false;
  }
}
