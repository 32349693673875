import { inject } from 'inversify-props';
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { EventGroup } from '../models/event-group.model';
import { IEventApiService } from '../services/event-api.service';
import { EventDetails } from '../models/event-details.model';
import dayjs from 'dayjs';

@Module
export default class EventsStoreModule extends VuexModule {
  private groups: EventGroup[] = [];
  private details: EventDetails[] = [];

  @inject()
  private eventApiService!: IEventApiService;

  @Action
  public async loadEvents(payload: { showOld: boolean, showOnlySignUp: boolean }): Promise<void> {
    const data = await this.eventApiService.getEvents(this.context.rootGetters.currentClub.id, payload);
    this.context.commit('setEventGroups', data);
  }
  @Action
  public async loadEventDetails(eventId: string): Promise<void> {
    const details = this.context.getters.eventDetails(eventId);
    if (details) {
      return;
    }
    const data = await this.eventApiService.getEventDetails(this.context.rootGetters.currentClub.id, eventId);
    this.context.commit('addEventDetails', data);
  }
  @Action
  public async eventsSubscribe(payload: {eventId: string, memberId: string, guestsCount: number, isPaidEvent: boolean}): Promise<void> {
    const data = await this.eventApiService.subscribeEvent(this.context.rootGetters.currentClub.id, payload.eventId, payload.memberId, payload.guestsCount)
    if (!data) {
      throw new Error('Failed to subscribe to event');
    }
    if (payload.isPaidEvent) {
      await this.context.dispatch('eventsPayment', { eventId: payload.eventId, memberId: payload.memberId })
      return;
    } else {
      this.context.commit('removeEventDetails', payload.eventId);
    }
  }
  @Action
  public async eventsReject(payload: {eventId: string, memberId: string}): Promise<void> {
    const data = await this.eventApiService.rejectEvent(this.context.rootGetters.currentClub.id, payload.eventId, payload.memberId);
    if (!data) {
      throw new Error('Failed to reject event');
    }
    this.context.commit('removeEventDetails', payload.eventId);
  }
  @Action
  public async eventsPayment(payload: {eventId: string, memberId: string}): Promise<void> {
    const data = await this.eventApiService.getPaymentUrl(this.context.rootGetters.currentClub.id, payload.eventId, payload.memberId);
    if (!data) {
      throw new Error('Failed to find payment url');
    }
    window.location.href = data;
  }


  @Mutation
  public setEventGroups(groups: EventGroup[]) {
    this.groups = groups;
  }
  @Mutation
  public addEventDetails(details: EventDetails) {
    this.details.push(details);
  }
  @Mutation
  public removeEventDetails(eventId: string) {
    this.details = this.details.filter(x => x.id !== eventId);
  }

  get eventGroups(): EventGroup[] {
    return this.groups;
  }

  get eventDetails(): (eventId: string) => EventDetails | null {
    return (eventId: string) => this.details.find(x => x.id === eventId) ?? null;
  }

  get myEventsCount(): number {
    let count = 0
      const currentDate = dayjs()
      if (this.groups) {
        for (let i = 0; i < this.groups.length; i++) {
            const myEvents = this.groups[i].events.filter(e => e.possibleAttendees
                && e.possibleAttendees.length > 0
                && dayjs(e.endDate) > currentDate)
            if (myEvents) {
                count += myEvents.length
            }
        }
    }
    return count;
  }
}