import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { TrainingPresence } from '../../models/training-presence.model';
import { Training } from '../../models/training.model';
import { TrainingsPaged } from '../../models/trainings-paged.model';
import { ITeamTrainingApiService } from '../team-training-api.service';

@injectable()
export class TeamTrainingApiService extends BaseApiService implements ITeamTrainingApiService {
  private readonly teamRoutes = {
    teams: 'teams',
    trainings: 'trainings',
    occurences: 'occurences',
    members: 'members',
    presence: 'presences',
    seasonTraining: 'season-trainings'
  }

  async getTeamTrainings(clubId: string, teamId: string, showHistory: boolean, currentPage: number, pageSize: number): Promise<TrainingsPaged> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamRoutes.seasonTraining}/${teamId}`;
    const response = await axios.get(url, {params: { showHistory, currentPage, pageSize }});
    return response.data;
  }

  async changePresenceStatus(clubId: string, teamId: string, trainingId: string, memberId: string, status: PresenceStatus): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamRoutes.trainings}/${trainingId}/${this.teamRoutes.teams}/${teamId}/${this.teamRoutes.members}/${memberId}/${this.teamRoutes.presence}`;
    const response = await axios.post(url, { status });
    return response.status === 200;
  }

  async getTrainingPresence(clubId: string, teamId: string, trainingId: string, occurenceId: string): Promise<TrainingPresence> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamRoutes.teams}/${teamId}/${this.teamRoutes.trainings}/${trainingId}/${this.teamRoutes.occurences}/${occurenceId}/${this.teamRoutes.presence}`;
    const response = await axios.get(url);
    return response.data;
  }
}