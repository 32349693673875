import { container } from 'inversify-props';
import { identifier, IDutyApiService } from './services/duty-api.service';
import { DutyApiService } from './services/impl/duty-api.service';

export class DutyModule {
  constructor() {
    this.addDependencies();
  }

  private addDependencies(): void {
    container.addTransient<IDutyApiService>(DutyApiService, identifier);
  }
}