import { BaseApiService } from "@/shared/services/base-api.service";
import axios from "axios";
import { injectable } from "inversify-props";
import { DocumentCategory } from "../../models/document-category.model";
import { IDocumentApiService } from "../doument.service";

@injectable()
export class DocumentApiService extends BaseApiService implements IDocumentApiService {
    private readonly documentsRoutes = {
        documents: 'documents',
        exist: 'exist'
    };

    private readonly downloadFileRoutes = {
        documents: 'documents',
        download: 'download'
    }

    async getDocumentsWithCategories(clubId: string) : Promise<DocumentCategory[]> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.documentsRoutes.documents}`;
        const response = await axios.get(url);

        return response.data.documentsCategories;
    }  
    
    async checkIfAnyDocumentExists(clubId: string) : Promise<boolean> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.documentsRoutes.documents}/${this.documentsRoutes.exist}`;
        const response = await axios.get(url);

        return response.data;
    }

    async downloadFile(clubId: string, fileId: string) : Promise<any> {

        //  
        //  axios here has some trouble with fetchig file data from API, so that's why XMLHttpRequest is ued
        //
        return new Promise( (resolve, reject) => {
            const memberToken = JSON.parse(localStorage.getItem('currentUser')!);
            
            const xhr = new XMLHttpRequest();

            xhr.open("GET", `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.downloadFileRoutes.documents}/${this.downloadFileRoutes.download}/${fileId}`);

            xhr.responseType = "arraybuffer";

            xhr.setRequestHeader('x-lisa-auth-token', memberToken?.token || '');
            xhr.setRequestHeader('Authorization', process.env.VUE_APP_API_AUTHORIZATION || '');

            xhr.onload = function () {
                if (this.status === 200) {
                    resolve(new Blob([this.response]));
                }
                else {
                    reject();
                }
            }

            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }

            xhr.send();
        });
    }
}