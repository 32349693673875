import { RouteConfig } from 'vue-router';

const contractsRoutes: RouteConfig[] = [
  {
    name: 'Contracts',
    path: '/contracts',
    component: () => import('../pages/Contracts.vue')
  },
  {
    name: 'ContractDetails',
    path: '/contracts/:id',
    component: () => import('../pages/ContractDetails.vue')
  }
]

export default contractsRoutes;