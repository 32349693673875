import { container } from 'inversify-props';
import { ICustomFieldsApiService } from './services/custom-fields-api.service';
import { CustomFieldsApiService } from './services/impl/custom-fields-api.service';
import { IMembershipCancellationsApiService } from './services/membership-cancellations-api.service';
import { MembershipCancellationsApiService } from './services/impl/membership-cancellations-api.service';

export class ProfileModule {
    constructor() {
        this.addDependencies();
    }

    private addDependencies() : void {
        container.addTransient<ICustomFieldsApiService>(CustomFieldsApiService);
        container.addTransient<IMembershipCancellationsApiService>(MembershipCancellationsApiService);
    }
}