import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { NewTaskType } from '../../models/new-task-type.model';
import { TeamTaskMatchTask } from '../../models/team-task-match-task.model';
import { TeamTaskMatch } from '../../models/team-task-match.model';
import { TeamTaskMember } from '../../models/team-task-member.model';
import { TeamTaskType } from '../../models/team-task-type.model';
import { ITeamTaskApiService } from '../team-task-api.service';

@injectable()
export class TeamTaskApiService extends BaseApiService implements ITeamTaskApiService {
  private readonly tasksRoutes = {
    teams: 'teams',
    tasks: 'tasks',
    taskTypes: 'tasks-types',
    members: 'members',
    matches: 'matches',
    tableView: 'table-view'
  }

  async getTeamTaskMatches(clubId: string, teamId: string, showHistory: boolean): Promise<TeamTaskMatch[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.tasks}`
    const response = await axios.get<TeamTaskMatch[]>(url, { params: { showHistory }});
    return response.data;
  }

  async getTeamTaskTypes(clubId: string, teamId: string): Promise<TeamTaskType[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.taskTypes}`
    const reponse = await axios.get<TeamTaskType[]>(url);
    return reponse.data;
  }

  async getTeamTaskMembers(clubId: string, teamId: string): Promise<TeamTaskMember[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.taskTypes}/${this.tasksRoutes.members}`;
    const reponse = await axios.get<TeamTaskMember[]>(url);
    return reponse.data;
  }

  async deleteTaskAssignment(clubId: string, teamId: string, matchId: string, taskId: string): Promise<number> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.matches}/${matchId}/${this.tasksRoutes.tasks}/${taskId}`;
    const response = await axios.delete(url);
    return response.status;
  }

  async assignTaskToMember(clubId: string, teamId: string, matchId: string, teamPersonId: string, taskTypeId: string): Promise<{status: number, result: TeamTaskMatchTask}> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.matches}/${matchId}/${this.tasksRoutes.tasks}`;
    const response = await axios.post(url, { teamPersonId, taskTypeId });
    return { status: response.status, result: response.data };
  }

  async createTaskType(clubId: string, teamId: string, taskType: NewTaskType): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.taskTypes}`;
    const response = await axios.post(url, taskType);
    return response.status === 201;
  }

  async updateTaskType(clubId: string, teamId: string, taskId: string, taskType: NewTaskType): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.taskTypes}/${taskId}`;
    const response = await axios.put(url, taskType);
    return response.status === 200;
  }

  async deleteTaskType(clubId: string, teamId: string, taskId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.taskTypes}/${taskId}`;
    const response = await axios.delete(url);
    return response.status === 204;
  }

  async getTeamTaskTableView(clubId: string, teamId: string, showHistory: boolean) {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.tasksRoutes.teams}/${teamId}/${this.tasksRoutes.tasks}/${this.tasksRoutes.tableView}`;
    const response = await axios.get(url, { params: { showHistory }})
    return response.data;
  }
}