












import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Wrapper extends Vue {
  @Getter
  isAppLoading!: boolean;

  @Action
  private loadCurrentMemberTeams!: () => Promise<void>
  @Action
  private setLoading!: (loadingn: boolean) => Promise<void>

  async created(): Promise<void> {
    await this.loadCurrentMemberTeams();
    await this.setLoading(false);
  }
}

