


















import { Component, Vue, Prop } from 'vue-property-decorator';
    
@Component
export default class ConfirmDeleteModal extends Vue {

    @Prop() title!:                  string | null;
    @Prop() message!:                string | null;
    @Prop() confirmButtonText!:      string | null;
    @Prop() confirmButtonVariant!:   string | null;
    @Prop() cancelButtonText!:       string | null;
    @Prop() cancelButtonVariant!:    string | null;

    get modalTitle(): string {
        return this.title ?? `${this.$t('shared.deleteConfirmation.title')}`;
    }

    get modalMessage(): string {
        return this.message ?? `${this.$t('shared.deleteConfirmation.message')}`;
    }

    get modalConfirmButtonText(): string {
        return this.confirmButtonText ?? `${this.$t('shared.deleteConfirmation.yes')}`;
    }

    get modalConfirmButtonVariant(): string {
        return this.confirmButtonVariant ?? 'primary';
    }

    get modalCancelButtonText(): string {
        return this.cancelButtonText ?? `${this.$t('shared.deleteConfirmation.cancel')}`;
    }

    get modalCancelButtonVariant(): string {
        return this.cancelButtonVariant ?? 'secondary';
    }

    handleOk() : void {
        this.$emit('onConfirm');
    }
}
