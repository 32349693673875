var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "sidebar container col-12 col-lg-3 border-right" },
        [
          _c(
            "div",
            {
              staticClass: "nav flex-column nav-pills rounder pr-3 pb-3",
              attrs: {
                id: "v-pills-tab",
                role: "tablist",
                "aria-orientation": "vertical",
              },
            },
            [
              _c(
                "b-nav-item-dropdown",
                {
                  attrs: {
                    id: "profileDropDown",
                    "toggle-class":
                      "nav-link h6 mb-0 align-items-center d-flex p-2 h-auto",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c("img", {
                              staticClass: "rounded-circle border mr-3",
                              attrs: {
                                src: _vm.avatarUrl,
                                alt: "",
                                width: "48",
                                heigh: "48",
                              },
                            }),
                            _c("div", [
                              _c(
                                "strong",
                                { staticClass: "d-block text-dark" },
                                [_vm._v(_vm._s(_vm.currentMemberFullName))]
                              ),
                              _vm.hasMembershipNumber
                                ? _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      _vm._s(_vm.currentMemberMembershipNumber)
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm.showAllMenu
                              ? _c("i", {
                                  staticClass:
                                    "far fa-sliders-h ml-auto border-left pl-3 mr-3",
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1057797400
                  ),
                },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "dropdown-item",
                      attrs: { "data-toggle": "pill", to: "/profiel" },
                    },
                    [_vm._v("Profiel")]
                  ),
                  _vm.membershipCancellationEnabled
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            "data-toggle": "pill",
                            to: "/membership-cancellations",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("membershipCancellations.title"))
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    {
                      staticClass: "dropdown-item",
                      on: {
                        click: function ($event) {
                          _vm.showLogoutModal = true
                        },
                      },
                    },
                    [_vm._v("Uitloggen")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "dropdown-divider mt-3" }),
              _c(
                "router-link",
                {
                  class: _vm.navClass,
                  attrs: { "exact-active-class": _vm.activeNavClass, to: "/" },
                },
                [
                  _c("i", { staticClass: "fas fa-fw fa-home-lg-alt mr-3" }),
                  _vm._v(" " + _vm._s(_vm.currentClub.name) + " "),
                ]
              ),
              _vm._l(_vm.availableMenu, function (item) {
                return _c(
                  "router-link",
                  {
                    key: item.path,
                    class: _vm.navClass,
                    attrs: {
                      "exact-active-class": _vm.activeNavClass,
                      to: item.path,
                    },
                  },
                  [
                    _c("i", { class: item.icon }),
                    _vm._v(" " + _vm._s(item.text) + " "),
                  ]
                )
              }),
              _c("div", { staticClass: "dropdown-divider mt-3" }),
              _vm._l(_vm.availablePersonalMenu, function (item) {
                return _c(
                  "router-link",
                  {
                    key: item.path,
                    class: _vm.navClass,
                    attrs: {
                      "exact-active-class": _vm.activeNavClass,
                      to: item.path,
                    },
                  },
                  [
                    _c("i", { class: item.icon }),
                    _vm._v(" " + _vm._s(item.text) + " "),
                  ]
                )
              }),
              [
                _vm._l(_vm.currentMemberPersonalTeams, function (team) {
                  return _c(
                    "router-link",
                    {
                      key: team.id,
                      class: _vm.navClass,
                      attrs: {
                        "exact-active-class": _vm.activeNavClass,
                        to: "/teams/" + team.id,
                      },
                    },
                    [
                      _c("i", { staticClass: "far fa-fw fa-users mr-3" }),
                      _vm._v(" " + _vm._s(team.name) + " "),
                    ]
                  )
                }),
                _c("div", { staticClass: "dropdown-divider mt-3" }),
                _c(
                  "div",
                  {
                    staticClass:
                      "container d-flex justify-content-between align-items-center",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-sm btn-link text-dark",
                        attrs: { href: _vm.dwfUrl, target: "_blank" },
                      },
                      [_vm._v("DWF")]
                    ),
                    _vm.currentMember.hockeyFoodAccess
                      ? _c(
                          "b-button",
                          {
                            staticClass: "btn btn-sm hockeyfood-btn",
                            attrs: {
                              disabled: _vm.isHockeyFoodLoading,
                              title: "HockeyFood",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.goToHockeyFood()
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/hockey-logo.svg"),
                                width: "20",
                                height: "20",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _c(
            "b-modal",
            {
              attrs: {
                title: "Uitloggen",
                "ok-title": "Uitloggen",
                "cancel-title": "Annuleren",
              },
              on: {
                ok: _vm.confirmLogout,
                cancel: function ($event) {
                  _vm.showLogoutModal = false
                },
              },
              model: {
                value: _vm.showLogoutModal,
                callback: function ($$v) {
                  _vm.showLogoutModal = $$v
                },
                expression: "showLogoutModal",
              },
            },
            [_vm._v(" Wil je echt uitloggen? ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }