import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { DutyDetails } from '../../models/duty-details.model';
import { IDutyApiService } from '../duty-api.service';

@injectable()
export class DutyApiService extends BaseApiService implements IDutyApiService {
  private readonly dutyRoutes = {
    members: 'members',
    tasks: 'tasks',
    taskAssignments: 'task-assignments'
  }

  async getDutyDetails(clubId: string, id: string): Promise<DutyDetails> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.tasks}/${id}`;
    const response = await axios.get(url);
    return response.data.task;
  }

  async registerToDuty(clubId: string, memberId: string, dutyId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.members}/${memberId}/${this.dutyRoutes.tasks}/${dutyId}/${this.dutyRoutes.taskAssignments}`;
    const response = await axios.put(url);
    return response.status === 201;
  }

  async unregisterFromDuty(clubId: string, memberId: string, dutyId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.members}/${memberId}/${this.dutyRoutes.tasks}/${dutyId}/${this.dutyRoutes.taskAssignments}`;
    const response = await axios.delete(url);
    return response.status === 204;
  }
}