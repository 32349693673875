import Vue from 'vue';
import Wrapper from './components/Wrapper.vue';
import Sidebar from './components/Sidebar.vue';
import Loader from './components/Loader.vue';

export class UiModule {
  constructor() {
    this.declareComponents();
  }

  private declareComponents() {
    Vue.component('Wrapper', Wrapper);
    Vue.component('Sidebar', Sidebar);
    Vue.component('Loader', Loader);
  }
}