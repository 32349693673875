import { RouteConfig } from 'vue-router';

const eventRoutes: RouteConfig[] = [
  {
    name: 'Events',
    path: '/events',
    component: () => import('../pages/Events.vue')
  },
  {
    name: 'EventsDetails',
    path: '/events/:eventId/:source?',
    component: () => import('../pages/EventDetails.vue')
  }
]

export default eventRoutes;