import { RouteConfig } from 'vue-router';

const tipsRoutes: RouteConfig[] = [
  {
    name: 'Tips',
    path: '/tips',
    component: () => import('../pages/Tips.vue')
  },
]

export default tipsRoutes;