var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "d-flex col-4 mt-3" }, [
        _vm._v(_vm._s(_vm.$t("teams.matches.header"))),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex col-8 mt-3 custom-control custom-switch justify-content-end",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "check-button", switch: "" },
                  on: { change: _vm.filterChanged },
                  model: {
                    value: _vm.showHistoricalMatches,
                    callback: function ($$v) {
                      _vm.showHistoricalMatches = $$v
                    },
                    expression: "showHistoricalMatches",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("teams.matches.showHistoricalMatches")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            items: _vm.teamMatches,
            fields: _vm.fields,
            "thead-class": "small",
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("teams.matches.noMatches"),
          },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(date)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.date) + " ")]
              },
            },
            {
              key: "cell(opponent)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(_vm.getOpponent(row.item)) + " ")]
              },
            },
            {
              key: "cell(time)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(row.item.time) + " "),
                  row.item.meetingTime
                    ? [
                        _c("br"),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(row.item.meetingTime)),
                        ]),
                      ]
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(locationName)",
              fn: function (row) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        row.item.locationName +
                          ", " +
                          (row.item.isHome
                            ? _vm.$t("teams.matches.home")
                            : _vm.$t("teams.matches.away"))
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "cell(refereesName)",
              fn: function (row) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        row.item.refereesName.length > 0
                          ? row.item.refereesName.join(", ")
                          : "-"
                      )
                  ),
                ]
              },
            },
            {
              key: "cell(attendance)",
              fn: function (row) {
                return [
                  row.item.canEditPresence
                    ? _c(
                        "div",
                        {
                          staticClass: "attendance",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.openEditPresenceModal(row.item.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.item.myTeamPresentPersonsCount +
                                  "/" +
                                  row.item.myTeamPersonsCount
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.item.myTeamPresentPersonsCount +
                                "/" +
                                row.item.myTeamPersonsCount
                            ) +
                            " "
                        ),
                      ]),
                ]
              },
            },
            {
              key: "cell(currentUserPresence)",
              fn: function (row) {
                return [
                  !_vm.changingPresencesRows.some(function (x) {
                    return x === row.item.id
                  })
                    ? _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn bg-white border p-1 mt-1",
                            attrs: {
                              type: "button",
                              id: "dropdownMenuButton",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                            on: {
                              click: [
                                function ($event) {
                                  return _vm.toggleShowDropdown(row.item.id)
                                },
                                function ($event) {
                                  $event.stopPropagation()
                                },
                              ],
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-check mx-2",
                              class: {
                                "presence-muted":
                                  row.item.currentUserPresence !=
                                  _vm.reactionPresent,
                                "text-success":
                                  row.item.currentUserPresence ==
                                  _vm.reactionPresent,
                              },
                            }),
                            _c("i", {
                              staticClass: "fas fa-question mx-2",
                              class: {
                                "presence-muted":
                                  row.item.currentUserPresence !=
                                  _vm.reactionUnknown,
                                "text-warning":
                                  row.item.currentUserPresence ==
                                  _vm.reactionUnknown,
                              },
                            }),
                            _c("i", {
                              staticClass: "fas fa-times mx-2",
                              class: {
                                "presence-muted":
                                  row.item.currentUserPresence !=
                                  _vm.reactionAbsent,
                                "text-danger":
                                  row.item.currentUserPresence ==
                                  _vm.reactionAbsent,
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showDropdown(row.item.id),
                                expression: "showDropdown(row.item.id)",
                              },
                            ],
                            staticClass: "dropdown-menu show",
                            attrs: { "aria-labelledby": "dropdownMenuButton" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        row.item.id,
                                        _vm.reactionPresent
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-check mx-2 text-success",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.present"))
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        row.item.id,
                                        _vm.reactionUnknown
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-question mx-2 text-warning",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.unknown"))
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        row.item.id,
                                        _vm.reactionAbsent
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-times mx-2 text-danger",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.absent"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm.changingPresencesRows.some(function (x) {
                        return x === row.item.id
                      })
                    ? _c("div", { staticClass: "col-12" }, [_c("Loader")], 1)
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }