import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { NewTeamDeposit } from '../../models/new-team-deposit.model';

import { ITeamDepositApiService } from '../team-deposit-api.service';

@injectable()
export class TeamDepositApiService extends BaseApiService implements ITeamDepositApiService {
  private readonly depositsRoutes = {
    teams: 'team-deposits',
    deposits: 'deposits',
    members: 'members',
  }

  async createTeamDeposit(clubId: string, teamId: string, memberId: string, deposit: NewTeamDeposit): Promise<boolean> {
      const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.depositsRoutes.teams}/${teamId}/${this.depositsRoutes.members}/${memberId}/${this.depositsRoutes.deposits}`;
      const response = await axios.post(url, deposit);
      return response.status === 201;
  }


  async deleteTeamDeposit(clubId: string, teamId: string, memberId: string, depositId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.depositsRoutes.teams}/${teamId}/${this.depositsRoutes.members}/${memberId}/${this.depositsRoutes.deposits}/${depositId}`;
    const response = await axios.delete(url);
    return response.status === 204;
  }
}