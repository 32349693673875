import { MenuItem } from '../models/menu-item.model';

export const menuData: MenuItem[] = [
  {
    path: '/events',
    text: 'Agenda en evenementen',
    icon: 'far fa-fw fa-calendar-week mr-3',
    modules: ['Events', 'Events_Registrations'],
    onlySuperUser: false,
    roles: []
  },
  {
    path: '/contracts',
    text: 'Contracten',
    icon: 'far fa-fw fa-file-contract mr-3',
      modules: ['Contracts'],
      onlySuperUser: true,
    roles: []
  },
  {
    path: '/nieuws',
    text: 'Nieuws',
    icon: 'far fa-fw fa-rss mr-3',
      modules: ['Communication'],
      onlySuperUser: false,
    roles: []
  },
  {
    path: '/trainingschema',
    text: 'Trainingsschema',
    icon: 'far fa-fw fa-dumbbell mr-3',
      modules: ['Trainings'],
      onlySuperUser: true,
    roles: [],
  },
  {
    path: '/organisatie',
    text: 'Organisatie',
      icon: 'far fa-fw fa-user-chart mr-3',
      onlySuperUser: false,
    modules: [],
    roles: [],
  },
  {
    path: '/documenten',
    text: 'Documenten',
    icon: 'far fa-fw fa-folders mr-3',
      modules: ['Documents'],
      onlySuperUser: true,
    roles: []
  },
  {
    path: '/arbitrage',
    text: 'Arbitrage',
    icon: 'far fa-fw fa-whistle mr-3',
      modules: ['Referees'],
      onlySuperUser: true,
    roles: ['referee']
  },
  {
    path: '/diensten',
    text: 'Diensten',
    icon: 'far fa-fw fa-coffee mr-3',
      modules: ['Dutyplanning'],
      onlySuperUser: true,
    roles: []
  },
  {
    path: '/teams',
    text: 'Alle teams',
    icon: 'far fa-fw fa-users mr-3',
      modules: ['Teams'],
      onlySuperUser: true,
    roles: []
  },
  {
    path: '/invoices',
    text: 'Invoices',
    icon: 'far fa-fw fa-users mr-3',
      modules: ['Invoices'],
      onlySuperUser: false,
    roles: []
  }
];