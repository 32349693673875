import { IClubApiService } from '../club-api.service';
import { Club } from '../../models/club.model';
import { BaseApiService } from '../../../../shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';

@injectable()
export class ClubApiService extends BaseApiService implements IClubApiService {
  async getClub(id: string): Promise<Club | null> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${id}`;
    const response = await axios.get(url);
    return Promise.resolve(response.data.club);
  } 

  async findClubs(federationId: string, search: string): Promise<Club[]> {
    const url = `${this.BASE_URL}/${this.routes.federations}/${federationId}/${this.routes.clubs}`;
    const response = await axios.get(url, { params: {
      namePattern: search,
      cityPattern: search
    }});
    return Promise.resolve(response.data.clubs.map((x: any) => x.club));
  }
}