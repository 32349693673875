import axios, { AxiosRequestConfig } from 'axios';

export class AuthInterceptor {
  public static setup(): void {
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      const token = process.env.VUE_APP_API_AUTHORIZATION;
      config.headers.Authorization = token;

      const memberToken = JSON.parse(localStorage.getItem('currentUser')!);
      if (memberToken) {
        config.headers.common['x-lisa-auth-token'] = memberToken.token;
      }
      return config;
    })
  }
}