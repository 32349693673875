














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import * as $ from 'jquery';


import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";

// In case of using other languages import here
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.nl.js";

import { GenerateHelper } from '../../shared/helpers/generate.helper';

import dayjs from 'dayjs';

@Component
export default class DatePicker extends Vue {

    @Prop() initValue: any; 

    @Prop() errorMsg!: string | null;
    @Prop() minDate!: string | null;
    @Prop() maxDate!: string | null;
    @Prop() customLabel!: string | null;
    @Prop({ default: false }) disabled!: boolean;

    isValid = true;
    selectedDate: string | null = null;
    id: string | null = null;

    beforeMount() {
        this.id = GenerateHelper.newGuid();
    }

    async mounted() {

        setTimeout(() => {

            const self = this;

            $(document).ready( () => {       

                self.selectedDate = self.initValue ? dayjs(self.initValue, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;

                $(`#${self.id}`).datepicker({
                    language: 'nl',
                    format: 'D-M-YYYY',
                    autoclose: true,
                    closeOnSelect: true,
                    todayHighlight: true,
                    updateViewDate: false
                })
                .on('hide', (e: any) => {
                    self.selectedDate = dayjs(e.date).format('YYYY-MM-DD');
                    self.$emit('onChange', self.selectedDate);
                })
            });

            if (self.minDate) {
                $(`#${this.id}`).datepicker('setStartDate', new Date(dayjs(self.minDate, 'YYYY-MM-DD').format('YYYY-MM-DD')));
            }

            if (self.maxDate) {
                $(`#${this.id}`).datepicker('setEndDate', new Date(dayjs(self.maxDate, 'YYYY-MM-DD').format('YYYY-MM-DD')));
            }

        }, 1);
    }

    get formattedDate () : string {
        if (this.customLabel) {
            return this.customLabel;
        }

        if (this.initValue === null) {
            return '';
        }
        
        return dayjs(this.initValue, 'YYYY-MM-DD').format('D-M-YYYY');
    }

    @Watch('errorMsg')
    onErrorMsgChange() {
        this.isValid = !this.errorMsg;
    }

    @Watch('minDate')
    onMinDateChange() {
        $(`#${this.id}`).datepicker('setStartDate', new Date(dayjs(this.minDate, 'YYYY-MM-DD').format('YYYY-MM-DD')));
    }

    @Watch('maxDate')
    onMaxDateChange() {
        $(`#${this.id}`).datepicker('setEndDate', new Date(dayjs(this.maxDate, 'YYYY-MM-DD').format('YYYY-MM-DD')));
    }
}
