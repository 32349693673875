





























































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Member } from '@/modules/login/models/member.model';
import { Club } from '@/modules/login/models/club.model';
import { ModuleHelper } from '@/shared/helpers/module.helper'
import { Team } from '@/modules/dashboard/models/team.model';
import { menuData } from '../data/menu.data';
import { personalMenuData } from '../data/personal-menu.data';
import { MenuItem } from '../models/menu-item.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { inject } from 'inversify-props';
import { IHockeyFoodSsoApiService, identifier } from '@/modules/login/services/hockeyfood-sso.service';

@Component
export default class Sidebar extends Vue {
  navClass = "nav-link d-flex align-items-center";
  activeNavClass = "active h6 mt-2 font-weight-bold";
  showLogoutModal = false;
  isLoading = true;
  isHockeyFoodLoading = false;

  @Getter
  currentClub!: Club;
  @Getter
  currentMember!: Member;
  @Getter
  currentMemberPersonalTeams!: Team[];
  @Getter
  anyDocumentExists!: boolean;
  @Getter
  showAllMenu!: boolean;
  @Getter
  hockeyFoodUrl!: string | null;

  private menu = menuData;
  private personalMenu = personalMenuData;
  private dwfUrl = 'https://dwf.hockeyweerelt.nl/';

  @Action
  private logout!: (withPost: boolean) => Promise<void>

  @Action
  private checkIfAnyDocumentExists!: () => Promise<void>

  @inject(identifier)
  private hockeyFoodApiService!: IHockeyFoodSsoApiService;

  async created(): Promise<void> {
    try {
      this.isLoading = true;
      await this.checkIfAnyDocumentExists();
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.isLoading = false;
    }
  }

  async goToHockeyFood() : Promise<void> {
    try {
      this.isHockeyFoodLoading = true;
      const ssoResponse = await this.hockeyFoodApiService.getHockeyFoodSso(this.currentClub.id, this.currentMember.id);
      window.open(ssoResponse.url, '_blank');
    }
    catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    }
    finally {
      this.isHockeyFoodLoading = false;
    }
  }

  async confirmLogout(): Promise<void> {
    await this.logout(true);
    await this.$router.push('/login');
  }

  get currentMemberFullName(): string {
    if (this.currentMember.middleName) {
      return `${this.currentMember.firstName} ${this.currentMember.middleName} ${this.currentMember.lastName}`;
    }

    return `${this.currentMember.firstName} ${this.currentMember.lastName}`
  }

  get hasMembershipNumber(): boolean {
    return this.currentMember.federationMembershipNumber !== null;
  }

  get currentMemberMembershipNumber(): string {
    return `${this.currentMember.federationMembershipNumber}`;
  }
  
  get availableMenu(): MenuItem[] {
    return this.menu.filter(x => this.isMenuAvailable(x));
  }

  get availablePersonalMenu(): MenuItem[] {
    return this.personalMenu.filter(x => this.isMenuAvailable(x));
  }

  get onlySuperUser(): boolean {
    return this.$route.query.showAllMenu != null && this.$route.query.showAllMenu[0] === 'jordy'
  }

  get avatarUrl() : string {
    if (this.currentMember?.assets[0]?.avatar.url) {
      return this.currentMember?.assets[0]?.avatar.url;
    }

    return '';
  }

  get membershipCancellationEnabled() : boolean {
    return this.currentClub.membershipCancellationEnabled;
  }

  private isMenuAvailable(x: MenuItem) {
      if (!this.showAllMenu) {
          return x.onlySuperUser;
      }
      else {
          if (x.modules.some((y: string) => y === 'Documents') && !this.anyDocumentExists) {
              return false;
          }
          return (!x.modules.some(y => y) || x.modules.some((y: string) => ModuleHelper.hasModule(y, this.currentClub)))
              && (!x.roles.some(y => y) || x.roles.some((y: string) => ModuleHelper.hasRole(y, this.currentMember)))
      } 
  }
}
