import VueRouter from 'vue-router';
import { Store } from 'vuex';
import Vue from 'vue';

export class AuthGuard {
    public static apply(router: VueRouter, store: Store<any>): void {
        router.beforeEach((to, from, next) => {
            const isLoggedIn = !!store.getters.currentMember;
            const anonymousOnlyRoute = to.meta?.anonymousOnly;
            const allowAnonymous = to.meta?.allowAnonymous;

            const showAll = store.getters.showAllMenu;
            const availablePages = [
                'Login', 
                'Dashboard', 
                'Contracts', 
                'ContractDetails', 
                'Documents', 
                'ResetPassword', 
                'ConfirmResetPassword', 
                'Duties', 
                'TrainingScheme',
                'Invoices',
                'Profile',
                'Referee',
                'AllTeams',
                'TeamInfo',
                'MembershipCancellations'
            ];


            if (allowAnonymous) {
                next();
            } else if (anonymousOnlyRoute && isLoggedIn) {
                // anonymous route like login - redirect to home page
                next({ name: 'Dashboard' });
            } else if (!anonymousOnlyRoute && !isLoggedIn) {
                // logged in route and not logged in - redirect to login
                next({ name: 'Login', query: { redirect: to.path } });
            } else if (!showAll && !availablePages.includes(to.name ?? '')) {
                next({ name: 'Dashboard' });
            }  else {
                // else pass
                next();
            }
        });

        router.afterEach((to: any, from: any) => {
            
            const DEFAULT_TAB_TITLE = 'My Environment';
            
            Vue.nextTick(() => {
                document.title = to.meta.title || DEFAULT_TAB_TITLE;
            })
        });
    }
}