import { RouteConfig } from 'vue-router';

const refereeRoutes: RouteConfig[] = [
  {
    name: 'Referee',
    path: '/arbitrage',
    component: () => import('../pages/Referee.vue')
  },
  {
    name: 'ConfirmReferee',
    path: '/arbitrage/confirm/:token',
    component: () => import('../pages/ConfirmReferee.vue'),
    props: true,
    meta: {
      allowAnonymous: true
    }
  }
]

export default refereeRoutes;