import { BaseApiService } from "@/shared/services/base-api.service";
import { StreamItemMessage } from "@microsoft/signalr";
import axios from "axios";
import { injectable } from "inversify-props";
import { CustomFieldAnswers } from "../../models/custom-fields-answers.model";
import { ICustomFieldsApiService } from "../custom-fields-api.service";

@injectable()
export class CustomFieldsApiService extends BaseApiService implements ICustomFieldsApiService {
    private readonly customFieldsRoutes = {
        customFields: 'custom_fields',
        answers: 'answers'
    };

    async getAnswers(clubId: StreamItemMessage) : Promise<CustomFieldAnswers> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.customFieldsRoutes.customFields}/${this.customFieldsRoutes.answers}`;
        const result = await axios.get(url);

        return result.data;
    }

    async updateAnswer(clubId: string, fieldId: string, value: string | null) : Promise<boolean> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.customFieldsRoutes.customFields}/${this.customFieldsRoutes.answers}`;

        const response = await axios.post(url, {
            customFieldId: fieldId,
            answer: value
        });

        return response.status === 200;
    }
    
}