
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { Federation } from '../models/federation.model';
import { Club } from '../models/club.model';
import { Member } from '../models/member.model';
import { IFederationApiService } from '@/modules/login/services/federation-api.service';
import { IClubApiService } from '@/modules/login/services/club-api.service';
import { IAuthApiService } from '@/modules/login/services/auth-api.service';
import { inject } from 'inversify-props';
import { LoginCredentials } from '../models/login-credentials.model';
import { IMemberApiService } from '@/modules/login/services/member-api.service';
import { IPasswordApiService } from '../services/password-api.service';
import { identifier, IHockeyFoodSsoApiService } from '../services/hockeyfood-sso.service';

@Module
export default class LoginStoreModule extends VuexModule {
  private readonly currentClubKey = 'currentClub';
  private readonly currentUserKey = 'currentUser';

  @inject()
  private federationApiService!: IFederationApiService;
  @inject()
  private clubApiService!: IClubApiService;
  @inject()
  private authApiService!: IAuthApiService;
  @inject()
  private memberApiService!: IMemberApiService;
  @inject()
  private passwordApiService!: IPasswordApiService;
  @inject(identifier)
  private hockeyFoodSsoApiService!: IHockeyFoodSsoApiService;

  private federation: Federation | null = null;
  private club: Club | null = null;
  private member: Member | null = null;
  private hockeyFoodSsoUrl: string | null = null;

  //Actions 
  @Action
  public async loadCurrentFederation(): Promise<void> {
    const federation = await this.federationApiService.getFederationByCurrentDomain();
    this.context.commit('setFederationInfo', federation);
  }
  @Action
  public async setCurrentClub(club: Club): Promise<void> {
    localStorage.setItem(this.currentClubKey, JSON.stringify(club))
    this.context.commit('setClubInfo', club);
    return this.context.dispatch('loadCurrentClub');
  }
  @Action
  public clearCurrentClub() {
    localStorage.removeItem(this.currentClubKey);
    this.context.commit('clearClubInfo');
  }
  @Action
  public async loadCurrentClub(): Promise<void> {
    const storageClub = localStorage.getItem(this.currentClubKey);
    const clubInfo = storageClub ? JSON.parse(storageClub) : null;
    if (!clubInfo) {
      return;
    }
    const club = await this.clubApiService.getClub(clubInfo.id);
    this.context.commit('setClubDetails', club);
  }
  @Action
  public async loadCurrentMember(): Promise<void> {
    if (this.member) {
      return;
    }
    const storageMember = JSON.parse(localStorage.getItem(this.currentUserKey)!);
    if (!storageMember) {
      return;
    }
    const member = await this.memberApiService.getMember(this.club!.id, storageMember.id);
    this.context.commit('setMemberDetails', member)
  }
  @Action
  public async login(credentials: LoginCredentials): Promise<void> {
    const response = await this.authApiService.login(this.club!.id, credentials);
    if (!response) {
      return Promise.reject();
    }

    const storageUser = {
      id: response.clubMember.id,
      token: response.token,
      clubId: this.club!.id
    }

    localStorage.setItem(this.currentUserKey, JSON.stringify(storageUser));
    return this.context.dispatch('loadCurrentMember');
  }

  @Action
  public async logout(withPost: boolean): Promise<void> {
    const storageUser = JSON.parse(localStorage.getItem(this.currentUserKey)!);
    if (withPost) {
      await this.authApiService.logout(this.club!.id, storageUser.token)
    }

    localStorage.removeItem(this.currentUserKey);
    this.context.commit('reset');
  }
  @Action
  public async resetPassword(payload: { fedNumber: string | null, clubNumber: string | null, emailAddress: string}): Promise<void> {
    await this.passwordApiService.resetPassword(this.club!.id, payload.fedNumber, payload.clubNumber, payload.emailAddress);
  }
  @Action
  public async confirmResetPassword(payload: { token: string, password: string }): Promise<void> {
    await this.passwordApiService.confirmResetPassword(payload.token, payload.password);
  }
  @Action
  public async resendResetPasswordToken(payload: { clubId: string, token: string }): Promise<void> {
    await this.passwordApiService.resendResetPasswordToken(payload.clubId, payload.token);
  }

  // Mutations
  @Mutation
  public setFederationInfo(federation: Federation) {
    this.federation = federation;
  }
  @Mutation
  public clearFederationInfo() {
    this.federation = null;
  }
  @Mutation
  setClubInfo(clubInfo: Club) {
    this.club = clubInfo;
  }
  @Mutation
  setClubDetails(details: Club) {
    this.club = details;
  }
  @Mutation
  clearClubInfo() {
    this.club = null;
  }
  @Mutation
  setMemberDetails(details: Member) {
    this.member = details;
  }
  @Mutation
  reset() {
    this.member = null;
  }
  @Mutation
  setHockeyFoodSsoUrl(url: string | null) {
    this.hockeyFoodSsoUrl = url;
  }
  //
  // Getters 
  get currentFederation(): Federation | null {
    return this.federation
  }
  get currentClub(): Club | null {
    return this.club;
  }
  get currentMember(): Member | null {
    return this.member;
  }
  get isLoggedIn(): boolean {
    return !!this.member!.token
  }
  get token(): any | null {
    return this.member ? this.member.token : null;
  }
  get checkIfClubHasModule() {
    return (module: any) => (this.club!.modules || []).includes(module);
  }
  get hockeyFoodUrl() : string | null {
    return this.hockeyFoodSsoUrl;
  }
  get currentMemberIsReferee() : boolean {
    return this.currentMember?.refereeInfo !== null;
  }
  //
}