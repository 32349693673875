var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading && _vm.matchPresence
    ? _c(
        "div",
        { staticClass: "col-12 pb-4" },
        [
          _c("h5", { staticClass: "col-12 pt-3 text-center" }, [
            _vm._v(_vm._s(_vm.$t("teams.matches.attendance"))),
          ]),
          _c("h6", { staticClass: "col-12 text-center" }, [
            _vm._v(
              _vm._s(_vm.matchPresence.teamName) +
                " - " +
                _vm._s(
                  _vm.matchPresence.opponentClub
                    ? _vm.matchPresence.opponentName +
                        ", " +
                        _vm.matchPresence.opponentClub
                    : "" + _vm.matchPresence.opponentName
                )
            ),
          ]),
          _c("h6", { staticClass: "col-12 pb-3 text-center" }, [
            _vm._v(_vm._s(_vm.matchPresence.date)),
          ]),
          _c("div", [
            _c("div", { staticClass: "col-12" }, [
              _vm._v(
                _vm._s(_vm.$t("shared.presence.present")) +
                  ": " +
                  _vm._s(_vm.presentPresenceCount)
              ),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _vm._v(
                _vm._s(_vm.$t("shared.presence.unknown")) +
                  ": " +
                  _vm._s(_vm.unknownPresenceCount)
              ),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _vm._v(
                _vm._s(_vm.$t("shared.presence.absent")) +
                  ": " +
                  _vm._s(_vm.absentPresenceCount)
              ),
            ]),
          ]),
          _vm.matchPresence.players.length > 0
            ? _c("div", { staticClass: "col-12 mt-4 font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.$t("teams.matches.players")) + " "),
              ])
            : _vm._e(),
          _vm._l(_vm.matchPresence.players, function (user, index) {
            return _c(
              "div",
              {
                key: "pp" + index,
                staticClass: "col-12 d-flex justify-content-between",
              },
              [
                _c(
                  "div",
                  { staticClass: "row col-9 d-flex align-items-center" },
                  [_vm._v(" " + _vm._s(user.fullName) + " ")]
                ),
                !_vm.changingPresenceUsers.some(function (x) {
                  return x === user.id
                })
                  ? _c(
                      "div",
                      { staticClass: "row d-flex justify-content-end col-3" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn bg-white border p-1 mt-1",
                            attrs: {
                              disabled: !user.canEdit,
                              type: "button",
                              id: "dropdownMenuButton",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                            on: {
                              click: [
                                function ($event) {
                                  return _vm.toggleShowDropdown(user.id)
                                },
                                function ($event) {
                                  $event.stopPropagation()
                                },
                              ],
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-check mx-2",
                              class: {
                                "presence-muted":
                                  user.presence != _vm.reactionPresent,
                                "text-success":
                                  user.presence == _vm.reactionPresent,
                              },
                            }),
                            _c("i", {
                              staticClass: "fas fa-question mx-2",
                              class: {
                                "presence-muted":
                                  user.presence != _vm.reactionUnknown,
                                "text-warning":
                                  user.presence == _vm.reactionUnknown,
                              },
                            }),
                            _c("i", {
                              staticClass: "fas fa-times mx-2",
                              class: {
                                "presence-muted":
                                  user.presence != _vm.reactionAbsent,
                                "text-danger":
                                  user.presence == _vm.reactionAbsent,
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showDropdown(user.id),
                                expression: "showDropdown(user.id)",
                              },
                            ],
                            staticClass: "dropdown-menu show",
                            attrs: { "aria-labelledby": "dropdownMenuButton" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        user.id,
                                        _vm.reactionPresent
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-check mx-2 text-success",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.present"))
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        user.id,
                                        _vm.reactionUnknown
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-question mx-2 text-warning",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.unknown"))
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        user.id,
                                        _vm.reactionAbsent
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-times mx-2 text-danger",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.absent"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c("div", { staticClass: "row col-3" }, [_c("Loader")], 1),
              ]
            )
          }),
          _vm.matchPresence.staffs.length > 0
            ? _c("div", { staticClass: "col-12 mt-4 font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.$t("teams.matches.assistans")) + " "),
              ])
            : _vm._e(),
          _vm._l(_vm.matchPresence.staffs, function (user, index) {
            return _c(
              "div",
              {
                key: "st" + index,
                staticClass: "col-12 d-flex justify-content-between",
              },
              [
                _c(
                  "div",
                  { staticClass: "row col-9 d-flex align-items-center" },
                  [_vm._v(" " + _vm._s(user.fullName) + " ")]
                ),
                !_vm.changingPresenceUsers.some(function (x) {
                  return x === user.id
                })
                  ? _c(
                      "div",
                      { staticClass: "row d-flex justify-content-end col-3" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn bg-white border p-1 mt-1",
                            attrs: {
                              disabled: !user.canEdit,
                              type: "button",
                              id: "dropdownMenuButton",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                            on: {
                              click: [
                                function ($event) {
                                  return _vm.toggleShowDropdown(user.id)
                                },
                                function ($event) {
                                  $event.stopPropagation()
                                },
                              ],
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-check mx-2",
                              class: {
                                "presence-muted":
                                  user.presence != _vm.reactionPresent,
                                "text-success":
                                  user.presence == _vm.reactionPresent,
                              },
                            }),
                            _c("i", {
                              staticClass: "fas fa-question mx-2",
                              class: {
                                "presence-muted":
                                  user.presence != _vm.reactionUnknown,
                                "text-warning":
                                  user.presence == _vm.reactionUnknown,
                              },
                            }),
                            _c("i", {
                              staticClass: "fas fa-times mx-2",
                              class: {
                                "presence-muted":
                                  user.presence != _vm.reactionAbsent,
                                "text-danger":
                                  user.presence == _vm.reactionAbsent,
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showDropdown(user.id),
                                expression: "showDropdown(user.id)",
                              },
                            ],
                            staticClass: "dropdown-menu show",
                            attrs: { "aria-labelledby": "dropdownMenuButton" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        user.id,
                                        _vm.reactionPresent
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-check mx-2 text-success",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.present"))
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        user.id,
                                        _vm.reactionUnknown
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-question mx-2 text-warning",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.unknown"))
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: [
                                    function () {
                                      return _vm.changePresenceStatus(
                                        user.id,
                                        _vm.reactionAbsent
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    },
                                  ],
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-fw fa-times mx-2 text-danger",
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("shared.presence.absent"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c("div", { staticClass: "row col-3" }, [_c("Loader")], 1),
              ]
            )
          }),
          _c("div", { staticClass: "col-12 mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary col-12",
                on: { click: _vm.closeModal },
              },
              [_vm._v(_vm._s(_vm.$t("shared.close")))]
            ),
          ]),
        ],
        2
      )
    : _c("div", { staticClass: "ta-loader" }, [_c("Loader")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }