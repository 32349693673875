import { container } from 'inversify-props';
import { TeamTrainingApiService } from './services/impl/team-training-api.service';
import { identifier, ITeamTrainingApiService } from './services/team-training-api.service';
import { ITeamApiService } from './services/team.service'
import { TeamApiService } from './services/impl/team-service';
import TeamMatchPresenceModal  from './pages/components/TeamMatchPresenceModal.vue'
import TeamMatches  from './pages/components/TeamMatches.vue'
import Vue from 'vue';
import { identifier as teamTaskIdentifier, ITeamTaskApiService } from './services/team-task-api.service';
import { TeamTaskApiService } from './services/impl/team-task-api.service';
import { identifier as teamDepositIdentifier, ITeamDepositApiService } from './services/team-deposit-api.service';
import { TeamDepositApiService } from './services/impl/team-deposit-api.service';

export class TeamModule {
  constructor() {
    this.addDependencies();
    this.declareComponents();
  }
  
  private declareComponents() {
    Vue.component('team-match-presence-modal', TeamMatchPresenceModal);
    Vue.component('team-matches', TeamMatches)
  }

  private addDependencies() {
    container.addTransient<ITeamApiService>(TeamApiService);
    container.addTransient<ITeamTrainingApiService>(TeamTrainingApiService, identifier);
    container.addTransient<ITeamDepositApiService>(TeamDepositApiService, teamDepositIdentifier);
    container.addTransient<ITeamTaskApiService>(TeamTaskApiService, teamTaskIdentifier);
  }

}