













import { Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Member } from './modules/login/models/member.model';
import { Club } from './modules/login/models/club.model';
import { Federation } from './modules/login/models/federation.model';

@Component
export default class App extends Vue {
  @Getter
  currentClub!: Club | null;
  @Getter
  currentFederation!: Federation | null;
  @Getter
  currentMember!: Member;

  private readonly fullscreenRoutes = ['Login', 'ResetPassword', 'ConfirmResetPassword', 'FillSurvey', 'DutyReminder', 'ConfirmReferee']

  get isFullscreenRoute(): boolean {
    return this.fullscreenRoutes.some(x => x === this.$route.name);
  }

  created(): void {
    this.setFavicon();
  }

  @Watch('currentClub')
  clubChanged(): void {
    this.setFavicon();
  }

  private setFavicon(): void {
    const favicon = document.getElementById('favicon-container');
    if (this.currentClub != null && this.currentClub.faviconUrl) {
      favicon!.setAttribute('href', this.currentClub!.faviconUrl)
    } else if (this.currentFederation != null && this.currentFederation!.faviconUrl) {
      favicon!.setAttribute('href', this.currentFederation!.faviconUrl)
    } else {
      favicon!.setAttribute('href', '/favicon.ico');
    }
  }
}
