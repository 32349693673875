import { RouteConfig } from 'vue-router';

const profileRoutes: RouteConfig[] = [
    {
        name: "Profile",
        path: '/profiel',
        component: () => import('../pages/Profile.vue')
    },
    {
        name: "MembershipCancellations",
        path: '/membership-cancellations',
        component: () => import('../pages/MembershipCancellations.vue')
    }
]

export default profileRoutes;