import { BaseApiService } from "@/shared/services/base-api.service";
import axios from "axios";
import { injectable } from "inversify-props";
import { ContractDetailsWrapped } from "../../models/contract-details-wrapped.model";
import ContractHoursForm from "../../models/contract-hours-form.model";
import { ContractWrapped } from "../../models/contract-wrapped.model";
import { SelectListItem } from "../../models/select-list-item.model";
import { IContractsApiService } from "../contracts.service";

@injectable()
export class ContractsApiService extends BaseApiService implements IContractsApiService {
    
    private readonly contractRoutes = {
        contracts: 'contracts'
    };

    private readonly selectsRoutes = {
        selects: "selects",
        hourTypes: "hour-types",
        teams: 'teams'
    }
    
    private readonly contractHoursRoutes = {
        contracts: "contracts",
        hours: "hours"
    }

    async getContractList(clubId: string, showExpired: boolean) : Promise<ContractWrapped> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.contractRoutes.contracts}`;
        const params = { showExpired };
        const response = await axios.get(url, { params });

        return response.data;
    }

    async getContractDetails(clubId: string, contractId: string) : Promise<ContractDetailsWrapped> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.contractRoutes.contracts}/${contractId}`;
        const response = await axios.get(url);

        return response.data;        
    }

    async getHourTypes(clubId: string) : Promise<SelectListItem[]> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.selectsRoutes.selects}/${this.selectsRoutes.hourTypes}`;
        const response = await axios.get(url);

        return response.data;
    }

    async getMemberTeams(clubId: string, date: string | null = null) : Promise<SelectListItem[]> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.selectsRoutes.selects}/${this.selectsRoutes.teams}`;
        const params = date !== null ? { date } : {};
        const response = await axios.get(url, { params });

        return response.data;
    }

    async addContractHours(clubId: string, contractId: string, model: ContractHoursForm) : Promise<void> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.contractHoursRoutes.contracts}/${contractId}/${this.contractHoursRoutes.hours}`;
        
        await axios.post(url, model);
    }

    async getForUpdateContractHours(clubId: string, contractId: string, contractHoursId: string) : Promise<ContractHoursForm> {
        
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.contractHoursRoutes.contracts}/${contractId}/${this.contractHoursRoutes.hours}/${contractHoursId}`;        
        const response = await axios.get(url);

        return response.data;
    }

    async updateContractHours(clubId: string, contractId: string, contractHoursId: string, model: ContractHoursForm) : Promise<void> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.contractHoursRoutes.contracts}/${contractId}/${this.contractHoursRoutes.hours}/${contractHoursId}`;
        
        await axios.put(url, model);
    }

    async deleteContractHours(clubId: string, contractId: string, contractHoursId: string) : Promise<void> {        
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.contractHoursRoutes.contracts}/${contractId}/${this.contractHoursRoutes.hours}/${contractHoursId}`;

        await axios.delete(url);
    }
}