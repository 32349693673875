import { container } from 'inversify-props';
import { IInvoiceApiService, identifier } from "./services/invoices.service";
import { InvoiceApiService } from './services/impl/invoices.service';

export class InvoicesModule {
    constructor () {
        this.addDependencies();
    }

    private addDependencies() {
        container.addTransient<IInvoiceApiService>(InvoiceApiService, identifier);
      }
}