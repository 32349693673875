import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

export class ParseInterceptor {
  public static setup(): void {
    axios.interceptors.response.use((response: AxiosResponse) => {
      if (response.data) {
        response.data = camelizeKeys(response.data);
      }
      return response;
    });

    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      const newConfig = {...config };
      if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
      }
      if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
      }
      return newConfig;
    });
  }
}