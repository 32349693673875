import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { Dashboard } from '../../models/dashboard.model';
import { IDashboardApiService } from '../dashboard-api.service';

@injectable()
export class DashboardApiService extends BaseApiService implements IDashboardApiService {
  private readonly dashboardRoutes = {
    program: 'program'
  }

  async getDashboard(federationId: string, fromDate: Date | null, toDate: Date | null): Promise<Dashboard> {
    const url = `${this.BASE_URL}/${this.routes.federations}/${federationId}/${this.dashboardRoutes.program}`
    const response = await axios.get(url, { params: { fromDate, toDate }});
    return response.data;
  }

}