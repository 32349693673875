import { BaseApiService } from "@/shared/services/base-api.service";
import { injectable } from "inversify-props";
import { IMembershipCancellationsApiService } from "../membership-cancellations-api.service";
import { CancellMembershipsRequest } from "../../models/cancell-memberships-request.model";
import { CancellationFormSettings } from "../../models/cancellation-form-settings.model";
import { CancellationReasonList } from "../../models/cancellation-reason-list.model";
import { MembershipList } from "../../models/membership-list.model";
import axios from "axios";

@injectable()
export class MembershipCancellationsApiService extends BaseApiService implements IMembershipCancellationsApiService {

    private readonly membershipCancellationRoutes = {
        memberships: 'memberships',
        cancellationReasons: 'cancellation_reasons',
        formSettings: 'form',
        cancel: 'cancel'

    };

    async getFormSettings(clubId: string) : Promise<CancellationFormSettings> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.membershipCancellationRoutes.memberships}/${this.membershipCancellationRoutes.formSettings}`;
        const result = await axios.get(url);

        return result.data;
    }

    async getMemberships(clubId: string) : Promise<MembershipList> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.membershipCancellationRoutes.memberships}`;
        const result = await axios.get(url);

        return result.data;
    }

    async getCancellationReasons(clubId: string) : Promise<CancellationReasonList> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.membershipCancellationRoutes.memberships}/${this.membershipCancellationRoutes.cancellationReasons}`;
        const result = await axios.get(url);

        return result.data;
    }

    async cancellMemberships(clubId: string, model: CancellMembershipsRequest) : Promise<boolean> {
        const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.membershipCancellationRoutes.memberships}/${this.membershipCancellationRoutes.cancel}`;
        const response = await axios.post(url, model);

        return response.status === 200;
    }

}