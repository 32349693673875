import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { FillSurveyClub, FillSurveyResponse } from "../models/fill-survey-response.model";
import { SurveyItem, SurveyQuestionGroup } from "../models/survey-question-group.model";
import { inject } from 'inversify-props';
import { ISurveyApiService, identifier } from "../services/surveys.service";
import { SurveyQuestionType } from "@/shared/enums/survey-question-type.enum";
import { SurveyItemType } from "@/shared/enums/survey-item-type.enum";
import i18n from '../../../i18n';

@Module
export default class SurveysStoreModule extends VuexModule {
    private surveyDetails: FillSurveyResponse | null = null;
    private questionGroup: SurveyQuestionGroup | null = null;

    private loadingQuestionGroup = false;

    private surveyHasStarted = false;
    private surveyHasBeenFinnished = false;

    @inject(identifier)
    private surveyApiService!: ISurveyApiService;


    @Action
    public async reset() : Promise<void> {
        this.context.commit('resetAll');
    }

    @Action
    public async fetchSurvey(surveyToken: string) : Promise<void> {
        const data = await this.surveyApiService.getFillSurvey(surveyToken);
        this.context.commit('setSurveyDetails', data);
    }

    @Action
    public async startSurvey(eventId: string | null, eventToken: string | null) : Promise<void> {

        const data = await this.surveyApiService.startSurvey(
            this.surveyDetails?.club.id ?? '', 
            this.surveyDetails?.survey.id ?? '', 
            this.surveyDetails?.personId ?? '',
            this.surveyDetails?.respondent.id ?? '', 
            eventId, 
            eventToken);

        this.context.commit('setQuestionGroup', data);
        this.context.commit('setSurveyStarted', true);
    }

    @Action
    public async resumeSurvey(eventId: string | null, eventToken: string | null) : Promise<void> {
        const data = await this.surveyApiService.resumeSurvey(
            this.surveyDetails?.club.id ?? '', 
            this.surveyDetails?.survey.id ?? '', 
            this.surveyDetails?.personId ?? '',
            this.surveyDetails?.respondent.id ?? '', 
            eventId, 
            eventToken);

        this.context.commit('setQuestionGroup', data);
        this.context.commit('setSurveyStarted', true);
    }

    @Action
    public async nextQuestionGroup() : Promise<void> {

        if (this.surveyDetails !== null && this.questionGroup !== null && this.clubInfo !== null) {
            try {

                const isValid = await this.context.dispatch('validate');
                if (!isValid) {
                    return;
                }

                this.context.commit('setQuestionGroupLoading', true);

                const data = await this.surveyApiService.nextQuestionGroup(
                    this.surveyDetails?.club.id, 
                    this.surveyDetails.survey.id, 
                    this.questionGroup);
    
                this.context.commit('setQuestionGroup', data);
            }
            catch (ex) {
                throw Error();
            }
            finally {
                this.context.commit('setQuestionGroupLoading', false);
            }
        }
    }

    @Action
    public async peviousQuestionGroup() : Promise<void> {
        
        try {
            this.context.commit('setQuestionGroupLoading', true);
            
            const data = await this.surveyApiService.peviousQuestionGroup(
                this.surveyDetails?.club.id ?? '', 
                this.surveyDetails?.survey.id ?? '',
                this.surveyDetails?.personId ?? '', 
                this.surveyDetails?.respondent.id ?? '', 
                this.questionGroup?.item.order ?? 0,
                this.surveyDetails?.eventId ?? '', 
                this.questionGroup?.eventToken ?? '');

            this.context.commit('setQuestionGroup', data);
        }
        catch (ex) {
            throw Error();
        }
        finally {
            this.context.commit('setQuestionGroupLoading', false);
        }
    }

    @Action
    public async finnishSurvey() : Promise<void> {
        if (this.surveyDetails !== null && this.questionGroup !== null && this.clubInfo !== null) {
            try {

                const isValid = await this.context.dispatch('validate');
                if (!isValid) {
                    return;
                }

                this.context.commit('setQuestionGroupLoading', true);

                await this.surveyApiService.finnishSurvey(
                    this.surveyDetails?.club.id, 
                    this.surveyDetails.survey.id, 
                    this.questionGroup);
    
                this.context.commit('setSurveyFinnished', true);
            }
            catch (ex) {
                throw Error();
            }
            finally {
                this.context.commit('setQuestionGroupLoading', false);
            }
        }
    }

    @Action
    public setAnwearValue(payload: { id: string, groupType: SurveyItemType, variableType: SurveyQuestionType, value: any }) : void {
        if (payload.groupType === SurveyItemType.Question) {
            this.context.commit('setSingleQuestionValue', { type: payload.variableType, value: payload.value });
        }
        else {
            this.context.commit('setGroupQuestionValue', { id: payload.id, type: payload.variableType, value: payload.value });
        }
    }

    @Action
    public async validate() : Promise<boolean> {
        if (this.questionGroup?.item.itemType === SurveyItemType.Question) {
            return await this.context.dispatch('validateQuestion', { groupType: SurveyItemType.Question, item: this.questionGroup.item })
        }
        else {
            const results: boolean[] = [];

            for (let i = 0; i < (this.questionGroup?.item.surveyItems?.length ?? 0); i++) {
                const x = await this.context.dispatch('validateQuestion', { groupType: SurveyItemType.Group, item: this.questionGroup?.item.surveyItems[i] });
                results.push(x)
            }
            
            return results.every(function (value) {
                return value === true || value === null;
            })
        }
    }

    @Action
    public async validateQuestion(payload: { groupType: SurveyItemType, item: SurveyItem }) : Promise<boolean> {
        
        const mutationName = payload.groupType === SurveyItemType.Question ? 'setSingleQuestionState' : 'setQuestionGroupState';

        const model : QuestionItemState = {
            id: payload.item.id,
            isValid: null,
            errorMessage: null
        };

        if (payload.item.itemType === SurveyItemType.Question) {
            if (payload.item.type === SurveyQuestionType.Int) {

                if (payload.item.intValue === null && payload.item.isRequired) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.fieldIsRequired')}`;
                }

                else if (payload.item.minValue !== null && (payload.item.intValue ?? Number.MIN_VALUE) < payload.item.minValue) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.minValue')}: ${payload.item.minValue}`;
                }    

                else if (payload.item.maxValue !== null && (payload.item.intValue ?? Number.MAX_VALUE) > payload.item.maxValue) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.maxValue')}: ${payload.item.maxValue}`;
                }

                else {
                    model.isValid = null;
                    model.errorMessage = null;
                }
            }
            else if (payload.item.type === SurveyQuestionType.Text) {

                if (payload.item.isRequired && (payload.item.textValue === null || payload.item.textValue === '')) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.fieldIsRequired')}`;
                }

                else {
                    model.isValid = null;
                    model.errorMessage = null;
                }
            }
            else if (payload.item.type === SurveyQuestionType.SingleSelectList) {
                if (payload.item.isRequired && payload.item.intValue === null) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.fieldIsRequired')}`;
                }

                else {
                    model.isValid = null;
                    model.errorMessage = null;
                }
            }
            else if (payload.item.type === SurveyQuestionType.MultiSelectList) {

                if (payload.item.isRequired && payload.item.intValues === null) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.fieldIsRequired')}`;
                }
                else if (payload.item.isRequired && payload.item.intValues.length === 0) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.fieldIsRequired')}`;
                }
                else {
                    model.isValid = null;
                    model.errorMessage = null;
                }

            }
            else if (payload.item.type === SurveyQuestionType.Date) {

                if (payload.item.isRequired && payload.item.dateTimeValue === null) {
                    model.isValid = false;
                    model.errorMessage = `${i18n.t('surveys.fieldIsRequired')}`;
                }
                else {
                    model.isValid = null;
                    model.errorMessage = null;
                }
            }
        }
        
        this.context.commit(mutationName, model)
        return model.isValid === true || model.isValid === null;
    }


    @Mutation
    public resetAll() {
        this.surveyDetails = null;
        this.questionGroup = null;
    }

    @Mutation 
    public setSurveyDetails(survey: FillSurveyResponse) {
        this.surveyDetails = survey;
    }

    @Mutation 
    public setQuestionGroup(questionGroup: SurveyQuestionGroup) {
        const mainItemTemp = mapQuestionGroupItem(questionGroup.item);

        if (questionGroup.item.itemType === SurveyItemType.Group && questionGroup.item.surveyItems.length > 0) {
            questionGroup.item.surveyItems.forEach(x => {
                mainItemTemp.surveyItems.push(mapQuestionGroupItem(x));
            })
        }

        const tempGroup : SurveyQuestionGroup = {
            hasPreviousGroup: questionGroup.hasPreviousGroup,
            hasNextGroup: questionGroup.hasNextGroup,
            eventId: questionGroup.eventId,
            eventToken: questionGroup.eventToken,
            surveyId: questionGroup.surveyId,
            respondentId: questionGroup.respondentId,
            personId: questionGroup.personId,
            item: mainItemTemp
        };
        
        this.questionGroup = tempGroup;
    }

    @Mutation
    public setSurveyStarted(value: boolean) {
        this.surveyHasStarted = value;
    }    

    @Mutation 
    public setQuestionGroupLoading(loading: boolean) {
        this.loadingQuestionGroup = loading;
    }

    @Mutation
    public setSingleQuestionValue(payload: {type: SurveyQuestionType, value: any | null}) {
        
        if (this.questionGroup !== null) {
            switch(payload.type) {
                case SurveyQuestionType.Bool:
                    this.questionGroup.item.boolValue = payload.value;
                    break;
                case SurveyQuestionType.Date:
                    this.questionGroup.item.dateTimeValue = payload.value;
                    break;
                case SurveyQuestionType.Int:
                    this.questionGroup.item.intValue = payload.value;
                    break;
                case SurveyQuestionType.Liker:
                    this.questionGroup.item.intValue = payload.value;
                    break;
                case SurveyQuestionType.Text:
                    this.questionGroup.item.textValue = payload.value;
                    break;
                case SurveyQuestionType.MultiSelectList:
                    this.questionGroup.item.intValues = payload.value;
                    break;
            }
        }
    }

    @Mutation
    public setGroupQuestionValue(payload: { id: string, type: SurveyQuestionType, value: any }) {
        const index = this.questionGroup?.item.surveyItems.findIndex(x => x.id === payload.id);
        
        if (this.questionGroup !== null && index !== undefined && index !== -1) {
            switch(payload.type) {
                case SurveyQuestionType.Bool:
                    this.questionGroup.item.surveyItems[index].boolValue = payload.value;
                    break;
                case SurveyQuestionType.Date:
                    this.questionGroup.item.surveyItems[index].dateTimeValue = payload.value;
                    break;
                case SurveyQuestionType.Int:
                    this.questionGroup.item.surveyItems[index].intValue = payload.value;
                    break;
                case SurveyQuestionType.Liker:
                    this.questionGroup.item.surveyItems[index].intValue = payload.value;
                    break;
                case SurveyQuestionType.Text:
                    this.questionGroup.item.surveyItems[index].textValue = payload.value;
                    break;
                case SurveyQuestionType.MultiSelectList:
                    this.questionGroup.item.surveyItems[index].intValues = payload.value;
                    break;
            }
        }
    }

    @Mutation
    public setSingleQuestionState(state: QuestionItemState ) {
        if (this.questionGroup !== null) {
            this.questionGroup.item.state.isValid = state.isValid;
            this.questionGroup.item.state.errorMessage = state.errorMessage;
        }
    }

    @Mutation
    public setQuestionGroupState(state: QuestionItemState) {
        const index = this.questionGroup?.item.surveyItems.findIndex(x => x.id === state.id);
        
        if (this.questionGroup !== null && index !== undefined && index !== -1) {
            this.questionGroup.item.surveyItems[index].state.isValid = state.isValid;
            this.questionGroup.item.surveyItems[index].state.errorMessage = state.errorMessage;
        }
    }

    @Mutation
    public setSurveyFinnished(value: boolean) {
        this.surveyHasBeenFinnished = value;
    }

    get surveyInfo() : FillSurveyResponse | null {
        return this.surveyDetails ?? null;
    }

    get questionGroupId() : string | null {
        return this.questionGroup?.item.id ?? null;
    }

    get currentQuestionGroup() : SurveyQuestionGroup | null {
        return this.questionGroup ?? null;
    }

    get clubInfo() : FillSurveyClub | null {
        return this.surveyDetails?.club ?? null;
    }

    getSingleQuestionValue() : any | null {
        return (type: SurveyQuestionType) => {
            switch(type) {
                case SurveyQuestionType.Bool:
                    this.questionGroup?.item.boolValue;
                    break;
                case SurveyQuestionType.Date:
                    this.questionGroup?.item.dateTimeValue;
                    break;
                case SurveyQuestionType.Int:
                    this.questionGroup?.item.intValue;
                    break;
                case SurveyQuestionType.Liker:
                    this.questionGroup?.item.intValue;
                    break;
                case SurveyQuestionType.Text:
                    this.questionGroup?.item.textValue;
                    break;
            }
        };
    }

    get questionGroupLoading() : boolean {
        return this.loadingQuestionGroup;
    }

    get surveyStarted() : boolean {
        return this.surveyHasStarted;
    }

    get surveyFinnished() : boolean {
        return this.surveyHasBeenFinnished;
    }
}

function mapQuestionGroupItem(item: SurveyItem) : SurveyItem {
    const itemTemp : SurveyItem = {
        textValue: item.textValue,
        intValue: item.intValue,
        intValues: item.intValues,
        boolValue: item.boolValue,
        dateTimeValue: item.dateTimeValue,
        state: {
            isValid: null,
            errorMessage: null                
        },
        surveyQuestionGroupId: item.surveyQuestionGroupId,
        id: item.id,
        surveyId: item.surveyId,
        itemType: item.itemType,
        order: item.order,
        name: item.name,
        description: item.description,
        type: item.type,
        shape: item.shape,
        typeName: item.typeName,
        minValue: item.minValue,
        maxValue: item.maxValue,
        isRequired: item.isRequired,
        answears: item.answears,
        surveyItems: []
    };

    return itemTemp;
}

export interface QuestionItemState {
    id: string;
    isValid: boolean | null;
    errorMessage: string | null;
}