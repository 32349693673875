import { inject } from 'inversify-props';
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Duty as DutyModel } from '../models/duty.model';
import { DutyRole } from '../models/duty-role.model';
import { DutyMemberAcceptance } from '../models/duty_member_acceptance.model';
import { identifier, IDutyApiService } from '../services/duty-api.service';
import { DutySeasonPoints } from '../models/duty_season_points.model';
import { MyDutyRoleItem } from '../models/my-dutyroleitem.model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

@Module
export default class DutyStoreModule extends VuexModule {
  private dutySeasonPoints: DutySeasonPoints | null = null;
  private otherDutiesData: DutyModel[] = [];

  private personAssignments: MyDutyRoleItem[] = [];

  private preselectedDutyItem: DutyMemberAcceptance | null = null;

  private dutyReminderToken: string | null = null;

  private showOtherDutiesAssigned = true;

  private myAssignmentsShowOld = false;
  private myAssignmentsPager = { 
    pageSize: 10, 
    currentPage: 1,
    totalEntries: 0,
    totalPages: 0
  };

  @inject(identifier)
  private dutiesDutyApiService!: IDutyApiService;

  @Action
  public async loadDuties(payload: { start: Date, end: Date }): Promise<void> {
    const data = await this.dutiesDutyApiService.getDuties(this.context.rootGetters.currentClub.id, payload.start, payload.end)
    this.context.commit('setOtherDuties', data);
  }

  @Action
  public async loadMyDutyAssignments() : Promise<void> {
    const data = await this.dutiesDutyApiService.getMyAssignedDuties(this.context.rootGetters.currentClub.id, this.myAssignmentsShowOld, this.myAssignmentsPager.currentPage, this.myAssignmentsPager.pageSize);

    this.context.commit('setMyAssignmentsPager', data.page)
    this.context.commit('setPersonAssignments', data.items);
  }

  @Action
  public async loadDutySeasonPoins() : Promise<void> {
    const data = await this.dutiesDutyApiService.getDutySeasonPoints(this.context.rootGetters.currentClub.id);
    this.context.commit('setDutySeasonPoints', data);
  }

  @Action
  public async assignToDuty(payload: { dutyId: string, roleId: string, occuranceId: string }): Promise<void> {
    const success = await this.dutiesDutyApiService.assignToDuty(this.context.rootGetters.currentClub.id, payload.dutyId, payload.roleId, payload.occuranceId);
    if (!success) {
      throw new Error();
    }
  }

  @Action
  public async unassignFromDuty(payload: {dutyId: string, assignmentId: string }) {
    const success = await this.dutiesDutyApiService.unAssignFromDuty(this.context.rootGetters.currentClub.id, payload.dutyId, payload.assignmentId);
    if (!success) {
      throw new Error();
    }
  }

  @Action
  public async getDutyReminderFromToken(token: string) : Promise<DutyMemberAcceptance> {
    const success = await this.dutiesDutyApiService.getDutyReminderFromToken(this.context.rootGetters.currentClub.id, token);

    if (!success) {
      throw new Error();
    }

    this.context.commit('setPreselectedDutyItem', success);
    this.context.commit('setDutyReminderToken', null);

    return success;
  }

  @Mutation
  public setDutySeasonPoints(points: DutySeasonPoints | null) {
    this.dutySeasonPoints = points;
  }

  @Mutation
  public setOtherDuties(otherDuties: DutyModel[]): void {
    this.otherDutiesData = otherDuties;
  }

  @Mutation
  public setPreselectedDutyItem(dutyItem: DutyMemberAcceptance | null) : void {
    this.preselectedDutyItem = dutyItem;
  }

  @Mutation
  public setDutyReminderToken(token: string | null) : void {
    this.dutyReminderToken = token;
  }

  @Mutation
  public setShowOtherDutiesAssigned(value: boolean) : void {
    this.showOtherDutiesAssigned = value;
  }

  @Mutation
  public setMyAssignmentsPagerPage(value: number) : void {
    this.myAssignmentsPager.currentPage = value;
  }

  @Mutation
  public setMyAssignmentsPager(page: {
    pageNumber: number;
    pageSize: number;
    totalEntries: number;
    totalPages: number;
  }) : void {
    this.myAssignmentsPager.totalEntries = page.totalEntries;
    this.myAssignmentsPager.totalPages = page.totalPages;
  }

  @Mutation
  public setPersonAssignments(items: MyDutyRoleItem[]) : void {
    this.personAssignments = items;
  }

  @Mutation
  public setMyAssignmentsShowOld(value: boolean) : void {
    this.myAssignmentsShowOld = value;
  }

  get otherDuties(): DutyModel[] {
    return this.otherDutiesData;
  }

  get otherDutiesFiltered(): DutyModel[] {
    if (this.showOtherDutiesAssigned) {
      return this.otherDutiesData;
    }

    const result = [] as DutyModel[];

    for (let y = 0; y < this.otherDutiesData.length; y++) {
        
      const temp = {...this.otherDutiesData[y]} as DutyModel;

      temp.roles = temp.roles.filter(r => !r.fullyAssigned);

      if (temp.roles.length > 0) {
        result.push(temp);
      }
    }

    return result;
  }

  get preselectedDuty(): { duty: DutyModel, role: DutyRole } | null {
    if (!this.preselectedDutyItem) {
      return null;
    }

    dayjs.extend(utc);

    const dutyItem = this.otherDutiesData.find(x => 
      dayjs(x.start).local().isSame(this.preselectedDutyItem?.startTime, 'day') &&
      x.occuranceId === this.preselectedDutyItem?.occurenceId);

    if (dutyItem) {
      const role = dutyItem.roles.find(x => x.id === this.preselectedDutyItem?.roleId);

      if (role) {
        return {
          duty: dutyItem,
          role: role
        }
      }
    }

    return null;
  }

  get reminderToken() : string | null {
    return this.dutyReminderToken;
  }

  get showDutiesAssigned() : boolean {
    return this.showOtherDutiesAssigned;
  }

  get seasonPoints() : DutySeasonPoints | null {
    return this.dutySeasonPoints;
  }

  get myAssignmentPager() : { pageSize: number, currentPage: number } {
    return this.myAssignmentsPager;
  }

  get showOldAssignments() : boolean {
    return this.myAssignmentsShowOld;
  }

  get assignedDutyRoleItems() : MyDutyRoleItem[] {
    return this.personAssignments;
  }
}