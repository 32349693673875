/* eslint @typescript-eslint/no-var-requires: "off" */
import './jQuery'
import 'reflect-metadata';
import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import loginRoutes from './modules/login/router';
import { Store } from 'vuex';
import Vuex from 'vuex';
import LoginStoreModule from './modules/login/store';
import UiStateModule from './ui/store';
import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import DashboardStoreModule from './modules/dashboard/store';
import RefereeStoreModule from './modules/referees/store';
import EventStoreModule from './modules/events/store';
import TeamStoreModule from './modules/teams/store';
import DocumentStoreModule from './modules/documents/store';
import ContractsStoreModule from './modules/contracts/store';
import OrganizationStoreModule from './modules/organization/store';
import InvoicesStoreModule from './modules/invoices/store';
import DutyStoreModule from './modules/duties/store';
import SurveysStoreModule from './modules/surveys/store';

import ClockPicker from './ui/components/ClockPicker.vue'
import ConfirmDeleteModal from './ui/components/ConfirmDeleteModal.vue';
import ConfirmModal from './ui/components/ConfirmModal.vue';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style/style.css';
import './style/custom-styles.css';
import './style/custom-styles.scss';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/webfonts/fa-light-300.woff2';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import i18n from './i18n'
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './modules/login/login.module';
import surveyRoutes from './modules/surveys/router';
import { SurveysModule } from './modules/surveys/surveys.module';
import { UiModule } from './ui/ui.module';
import dashboardRoutes from './modules/dashboard/router';
import { AuthGuard } from './shared/guards/auth.guard';
import organizationRoutes from './modules/organization/router';
import contractsRoutes from './modules/contracts/router';
import documentRoutes from './modules/documents/router';
import dutiesRoutes from './modules/duties/router';
import eventRoutes from './modules/events/router';
import invoicesRoutes from './modules/invoices/router';
import matchesRoutes from './modules/matches/router';
import profileRoutes from './modules/profile/router';
import photoAlbumRoutes from './modules/photo-album/router';
import refereeRoutes from './modules/referees/router';
import teamsRoutes from './modules/teams/router';
import tipsRoutes from './modules/tips/router';
import trainingRoutes from './modules/training/router';
import newsRoutes from './modules/news/router';
import defaultRoutes from './ui/routes/default';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import VModal from 'vue-js-modal'
import { RefereeModule } from './modules/referees/referee.module';
import { EventModule } from './modules/events/event.module';
import { TeamModule } from './modules/teams/team.module';
import { DocumentModule } from './modules/documents/documents.module';
import { ContractsModule } from './modules/contracts/contracts.module';
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { InvoicesModule } from './modules/invoices/invoices.module';
import { OrganizationModule } from './modules/organization/organization.module';
import { TrainingScheduleModule } from './modules/training/trainingSchedule.module';
import { DutyModule } from './modules/duties/duty.module';
import { ProfileModule } from './modules/profile/profile.module';
import ProfileStoreModule from './modules/profile/store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import DatePicker from './ui/components/DatePicker.vue'

if (process.env.VUE_APP_APPINSIGHTS_CONNECTIONSTRING) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.VUE_APP_APPINSIGHTS_CONNECTIONSTRING,
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

Sentry.init({
  environment: process.env.VUE_APP_ENVIRONMENT,
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
});

class Bootstrap {
  constructor() {
    this.loadModules();
    this.loadVue();
  }

  private loadModules(): void {
    new SharedModule();
    new UiModule();
    new LoginModule();
    new DashboardModule();
    new RefereeModule();
    new EventModule();
    new TeamModule();
    new DocumentModule();
    new ContractsModule();
    new InvoicesModule();
    new OrganizationModule();
    new TrainingScheduleModule();
    new DutyModule();
    new SurveysModule();
    new ProfileModule();
  }


  private loadVue(): void {
    Vue.config.productionTip = false;

    const router = this.loadRouter();
    const store = this.loadStore();
    AuthGuard.apply(router, store);

    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    Vue.use(ToastPlugin);
    Vue.use(Vuelidate as any)
    Vue.use(VModal, { dynamicDefaults: { height: 'auto' } });


    dayjs.locale('nl');
    
    Vue.component('clock-picker', ClockPicker);
    Vue.component('confirm-delete-modal', ConfirmDeleteModal);
    Vue.component('confirm-modal', ConfirmModal);
    Vue.component('date-picker', DatePicker);

    store.dispatch('loadCurrentFederation')
      .then(() => store.dispatch('loadCurrentClub'))
      .then(() => store.dispatch('loadCurrentMember'))
      .then(() => {
        this.renderApp(router, store);
      })
      .catch(async () => {
        await store.dispatch('logout', false)
        this.renderApp(router, store);
      })
  }

  private loadRouter(): VueRouter {
    Vue.use(VueRouter);
    const routes = [
      ...contractsRoutes,
      ...dashboardRoutes,
      ...documentRoutes,
      ...dutiesRoutes,
      ...eventRoutes,
      //...familyRoutes,    Temporary disabled by LX-8235
      ...invoicesRoutes,
      ...loginRoutes,
      ...matchesRoutes,
      //...materialRoutes,  Temporary disabled by LX-8235
      ...newsRoutes,
      ...organizationRoutes,
      //...personalRoutes,  Temporary disabled by LX-8235
      ...photoAlbumRoutes,
      ...refereeRoutes,
      ...teamsRoutes,
      ...tipsRoutes,
      ...trainingRoutes,
      ...defaultRoutes,
      ...surveyRoutes,
      ...profileRoutes
    ]

    return new VueRouter({ mode: 'history', routes });
  }

  private loadStore(): Store<any> {
    Vue.use(Vuex);
    return new Store({
      modules: {
        LoginStoreModule,
        UiStateModule,
        DashboardStoreModule,
        RefereeStoreModule,
        EventStoreModule,
        TeamStoreModule,
        DocumentStoreModule,
        ContractsStoreModule,
        OrganizationStoreModule,
        InvoicesStoreModule,
        DutyStoreModule,
        SurveysStoreModule,
        ProfileStoreModule
      }
    });
  }

  private renderApp(router: VueRouter, store: Store<any>): void {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
}

new Bootstrap();