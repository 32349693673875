import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { Team } from '../../models/team.model';
import { ITeamsApiService } from '../teams-api.service';

@injectable()
export class TeamsApiService extends BaseApiService implements ITeamsApiService {
  private readonly teamsRoutes = {
    teams: 'teams'
  }

  async getMyTeams(clubId: string): Promise<{ personalTeams: Team[]; favouriteTeams: Team[]; }> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.teamsRoutes.teams}`;
    const response = await axios.get(url);
    const personalTeams = response.data.personalTeams;
    const favouriteTeams = response.data.favouriteTeams;

    return { personalTeams, favouriteTeams };
  }

}