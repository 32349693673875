
import { inject } from 'inversify-props';
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Team } from '../models/team.model';
import { IDashboardApiService } from '../services/dashboard-api.service';
import { ITeamsApiService } from '../services/teams-api.service';
import { IMatchApiService } from '../services/match-api.service';
import { Tile } from '../models/tile.model';
import { PinnedItem } from '../models/pinned-item.model';
import { PresenceStatus } from '../../../shared/enums/presence-status.enum';
import { Match } from '../models/match.model';
import { DutyDetails } from '../models/duty-details.model';
import { ITrainingApiService } from '../services/training-api.service';
import { IDutyApiService } from '../services/duty-api.service';
import { TrainingDetails } from '../models/training-details.model';

@Module
export default class DashboardStoreModule extends VuexModule {
  @inject()
  private teamsApiService!: ITeamsApiService;
  @inject()
  private dashboardApiService!: IDashboardApiService;
  @inject()
  private matchApiService!: IMatchApiService;
  @inject()
  private trainingApiService!: ITrainingApiService;
  @inject()
  private dutyApiService!: IDutyApiService;

  private pinnedItems: PinnedItem[] = [];
  private tiles: Tile[] = [];
  private personalTeams: Team[] = [];
  private favouriteTeams: Team[] = [];
  private dashboardMatchDetails: Match | null = null;
  private dashboardDutyDetails: DutyDetails[] = [];
  private dashboardTrainingDetails: TrainingDetails[] = [];
  
  @Action
  public async loadCurrentMemberTeams(): Promise<void> {
    const teams = await this.teamsApiService.getMyTeams(this.context.rootGetters.currentClub.id);
    this.context.commit('setPersonalTeams', teams.personalTeams);
    this.context.commit('setFavouriteTeams', teams.favouriteTeams);
  }
  @Action
  public async loadDashboard(): Promise<void> {
    const dashboard = await this.dashboardApiService.getDashboard(this.context.rootGetters.currentFederation.id, null, null)
    this.context.commit('setDashboardTiles', dashboard.tiles);
    this.context.commit('setDashboardPinnedItems', dashboard.pinnedItems)
  }
  @Action
  public async changeMatchPresenceStatus(payload: { clubId: string, matchId: string, teamId: string, status: PresenceStatus, memberId?: string}): Promise<void> {
    const response = await this.matchApiService.changeMatchPresenceStatus(payload.clubId, payload.matchId, payload.teamId,
      (payload.memberId != undefined ? payload.memberId : this.context.rootGetters.currentMember.id), payload.status
    );
    if (!response) {
      throw new Error('Failed to change presence status');
    }
  }
  @Action
  public async loadDashboardMatchDetails(matchId: string): Promise<void> {
    const details = await this.matchApiService.getMatchDetails(this.context.rootGetters.currentClub.id, matchId);
    this.context.commit('setDashboardMatchDetails', details);
  }
  @Action
  public async changeTrainingPresenceStatus(payload: {clubId: string, trainingId: string, teamId: string, status: PresenceStatus}): Promise<void> {
    const response = await this.trainingApiService.changeTrainingPresenceStatus(payload.clubId, payload.trainingId, payload.teamId, 
      this.context.rootGetters.currentMember.id, payload.status
    );
    if (!response) {
      throw new Error('Failed to change presence status');
    }
  }
  @Action
  public async changeTrainingPresenceStatusOtherMember(payload: {clubId: string, trainingId: string, teamId: string, memberId: string, status: PresenceStatus}): Promise<void> {
    const response = await this.trainingApiService.changeTrainingPresenceStatus(payload.clubId, payload.trainingId, payload.teamId, 
      payload.memberId, payload.status
    );
    if (!response) {
      throw new Error('Failed to change presence status');
    }
  }
  @Action
  public async loadDashboardDutyDetails(dutyId: string): Promise<void> {
    const dutyDetails = this.context.getters.dutyDetails(dutyId);
    if (dutyDetails) {
      return;
    }

    const fetchedDetails = await this.dutyApiService.getDutyDetails(this.context.rootGetters.currentClub.id, dutyId);
    this.context.commit('addDutyDetails', fetchedDetails);
  }
  @Action
  public async registerToDuty(dutyId: string): Promise<void> {
    const response = await this.dutyApiService.registerToDuty(this.context.rootGetters.currentClub.id, this.context.rootGetters.currentMember.id, dutyId);
    if (!response) {
      throw new Error('Failed to register to duty');
    }
  }
  @Action
  public async unregisterFromDuty(dutyId: string): Promise<void> {
    const response = await this.dutyApiService.unregisterFromDuty(this.context.rootGetters.currentClub.id, this.context.rootGetters.currentMember.id, dutyId);
    if (!response) {
      throw new Error('Failed to unregister from duty');
    }
  }
  @Action
  public async getTrainingsDetails(payload: { trainingId: string, occurenceId: string, teamId: string }) : Promise<void> {
    const response = await this.trainingApiService.getTrainingsDetails(this.context.rootGetters.currentClub.id, payload.trainingId, payload.occurenceId, payload.teamId);
    this.context.commit('setTrainingDetails', response);
  } 

  @Mutation
  public setPersonalTeams(teams: Team[]) {
    this.personalTeams = teams;
  }
  @Mutation
  public setFavouriteTeams(teams: Team[]) {
    this.favouriteTeams = teams;
  }
  @Mutation
  public setDashboardTiles(tiles: Tile[]) {
    this.tiles = tiles;
  }
  @Mutation 
  public setDashboardPinnedItems(items: PinnedItem[]) {
    this.pinnedItems = items;
  }
  @Mutation
  public setDashboardMatchDetails(match: Match) {
    this.dashboardMatchDetails = match;
  }
  @Mutation
  public addDutyDetails(duty: DutyDetails) {
    this.dashboardDutyDetails.push(duty);
  }
  @Mutation
  public setTrainingDetails(details: TrainingDetails) {
    const index = this.dashboardTrainingDetails.findIndex(x => x.id === details.id && x.occurenceId === details.occurenceId);

    if (index !== -1) {
      this.dashboardTrainingDetails[index] = details;
    }
    else {
      this.dashboardTrainingDetails.push(details);
    }
  }

  get currentMemberFavouriteTeams(): Team[] {
    return this.favouriteTeams;
  }
  get currentMemberPersonalTeams(): Team[] {
    return this.personalTeams
  }
  get dashboardTiles(): Tile[] {
    return this.tiles;
  }
  get dashboardPinnedItems(): PinnedItem[] {
    return this.pinnedItems;
  }
  get matchDetails(): Match | null {
    return this.dashboardMatchDetails;
  }
  get dutyDetails(): (dutyId: string) => DutyDetails | undefined {
    return (dutyId: string) => this.dashboardDutyDetails.find(x => x.id === dutyId);
  }
  get trainingDetails(): (trainingId: string, occurenceId: string) => TrainingDetails | undefined {
    return (trainingId: string, occurenceId: string) => this.dashboardTrainingDetails.find(x => x.id === trainingId && x.occurenceId === occurenceId);
  }
}