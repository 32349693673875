import { RouteConfig } from 'vue-router';

const matchesRoutes: RouteConfig[] = [
  {
    name: 'Matches',
    path: '/oefenwedstrijden',
    component: () => import('../pages/Matches.vue')
  }
]

export default matchesRoutes;