import { RouteConfig } from 'vue-router'; 

const surveyRoutes: RouteConfig[] = [
  { 
    path: '/fillsurvey/:token?', 
    name: 'FillSurvey', 
    component: () => import('../pages/FillSurveyPage.vue'),
    props: true,
    meta: {
        allowAnonymous: true
    }
  }
]

export default surveyRoutes;