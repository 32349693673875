var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper pt-5 pb-5 container d-flex col-12" },
    [
      _c("Sidebar"),
      _c(
        "div",
        { staticClass: "content container col-12 col-lg-9" },
        [
          _vm.isAppLoading
            ? _c("div", { staticClass: "loader-container" }, [_c("Loader")], 1)
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }