
















































































import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { MatchPresence } from '../../models/match-presence.model';
import { inject } from 'inversify-props';
import { ITeamApiService } from '../../services/team.service';
import { Match } from '../../models/match.model';
import { Member } from '@/modules/login/models/member.model';

@Component
export default class TeamMatchPresenceModal extends Vue {

  @Prop({ default: '' }) readonly matchId!: string
  @Prop({ default: '' }) readonly clubId!: string

  matchPresence: MatchPresence | null = null;
  
  isLoading = true;
  changingPresenceUsers: string[] = [];  
  
  selectedDropdown: string | null = null;

  @inject()
  private teamApiService!: ITeamApiService;

  reactionPresent: PresenceStatus = PresenceStatus.Present;
  reactionUnknown: PresenceStatus = PresenceStatus.Unknown;
  reactionAbsent: PresenceStatus = PresenceStatus.Absent;

  @Action
  changeMatchPresenceStatus!: (payload: { clubId: string, matchId: string, teamId: string, status: PresenceStatus, memberId?: string}) => Promise<void>;

  @Getter
  teamMatches!: Match[];
  @Getter
  currentMember!: Member;

  get data(): any {
    return this.matchPresence;
  }
  
  async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.matchPresence = await this.teamApiService.getMatchPresence(this.clubId, this.$route.params.id, this.matchId);
    } 
    catch {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
    }
    finally {
      this.isLoading = false;
    }
  }

  async changePresenceStatus(memberId: string, status: PresenceStatus): Promise<void> {
    try {
      this.changingPresenceUsers.push(memberId);

      const payload = {
        clubId: this.clubId,
        matchId: this.matchId,
        teamId: this.$route.params.id,
        status: status,
        memberId: memberId,
      }      

      await this.changeMatchPresenceStatus(payload);
      this.matchPresence = await this.teamApiService.getMatchPresence(this.clubId, this.$route.params.id, this.matchId);

      const playerPresence = this.matchPresence!.players.find(x => x.id === memberId);
      const staffPresence = this.matchPresence!.staffs.find(x => x.id === memberId);
      playerPresence ? playerPresence!.presence = status : '';
      staffPresence ? staffPresence!.presence = status : '';

      this.updateMatchesStorage();

      if (this.currentMember.id === memberId) {
        this.updateCurrentMemberPresence(status);
      }
      
      ToastHelper.showSuccess(this.$bvToast, `${this.$t('shared.presenceChanged')}`);
    } 
    catch {
      ToastHelper.showError(this.$bvToast, `${this.$t('shared.errorMessage')}`);
    } 
    finally {
      this.changingPresenceUsers = this.changingPresenceUsers.filter(x => x !== memberId);
      this.toggleShowDropdown(memberId);
    }
  }

  toggleShowDropdown(id: string) : void {
    if (!this.selectedDropdown || this.selectedDropdown !== id) {
      this.selectedDropdown = id;
      return;
    }
    
    this.selectedDropdown = null;
  }

  showDropdown(id: string) : boolean {
    if (!this.showDropdown) {
      return false;
    }

    return this.selectedDropdown === id;
  }
  
  closeModal(): void {
    this.$emit('close');
  }

  get unknownPresenceCount(): number {
    if (!this.matchPresence) {
      return 0;
    }

    var players = this.matchPresence.players.filter(x => x.presence === PresenceStatus.Unknown).length ?? 0;
    var staff = this.matchPresence.staffs.filter(x => x.presence === PresenceStatus.Unknown).length ?? 0;

    return players + staff;
  }

  get presentPresenceCount(): number {
    if (!this.matchPresence) {
      return 0;
    }

    var players = this.matchPresence.players.filter(x => x.presence === PresenceStatus.Present).length ?? 0;
    var staff = this.matchPresence.staffs.filter(x => x.presence === PresenceStatus.Present).length ?? 0;

    return players + staff;
  }

  
  get playersPresentPresenceCount(): number {
    if (!this.matchPresence) {
      return 0;
    }

    return this.matchPresence.players.filter(x => x.presence === PresenceStatus.Present).length ?? 0;
  }

  get absentPresenceCount(): number {
    if (!this.matchPresence) {
      return 0;
    }

    var players = this.matchPresence.players.filter(x => x.presence === PresenceStatus.Absent).length ?? 0;
    var staff = this.matchPresence.staffs.filter(x => x.presence === PresenceStatus.Absent).length ?? 0;

    return players + staff;
  }

  private updateMatchesStorage(): void {    
     this.teamMatches.forEach((element, index) => {
        if (element.id === this.matchId) {           
              this.teamMatches[index].myTeamPresentPersonsCount = this.playersPresentPresenceCount;
          }         
      });

      this.$store.commit('setTeamMatches', this.teamMatches);
  }

  private updateCurrentMemberPresence(presence: PresenceStatus): void {    
     this.teamMatches.forEach((element, index) => {
        if (element.id === this.matchId) {           
              this.teamMatches[index].currentUserPresence = presence;
          }         
      });

      this.$store.commit('setTeamMatches', this.teamMatches);
  }

}
