import { BaseApiService } from '@/shared/services/base-api.service';
import axios from 'axios';
import { injectable } from 'inversify-props';
import { DutyDay } from '../../models/duty-day.model';
import { Duty as DutyModel } from '../../models/duty.model';
import { DutyMemberAcceptance } from '../../models/duty_member_acceptance.model';
import { DutyReminderAcceptance } from '../../models/duty_reminder_acceptance.model';
import { DutySeasonPoints } from '../../models/duty_season_points.model';
import { MyDutyRoleItemPaged } from '../../models/my-dutyroleitem-paged.model';
import { MyDutyRoleItem } from '../../models/my-dutyroleitem.model';
import { IDutyApiService } from '../duty-api.service';

@injectable()
export class DutyApiService extends BaseApiService implements IDutyApiService {
  private readonly dutyRoutes = {
    duties: 'duties',
    assignments: 'assignments',
    token: 'token',
    seasonPoints: 'season_points',
    my: "my"
  }

  private readonly dutyReminderRoutes = {
    dutyReminder: 'dutyreminder',
    assign: 'assign'
  };

  public async getDuties(clubId: string, start: Date, end: Date): Promise<DutyModel[]> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.duties}`;
    const response = await axios.get(url, { params: { start: start.toISOString(), end: end.toISOString() } });
    return response.data.otherDuties;
  }

  public async getMyAssignedDuties(clubId: string, showOld: boolean, pageNumber: number, pageSize: number): Promise<MyDutyRoleItemPaged> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.duties}/${this.dutyRoutes.my}`;
    const params = {
      pageNumber,
      pageSize,
      showOld
    };

    const response = await axios.get(url, { params });
    return response.data;
  }

  public async assignToDuty(clubId: string, dutyId: string, roleId: string, occuranceId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.duties}/${dutyId}/${this.dutyRoutes.assignments}`;
    const response = await axios.post(url, { roleId, occuranceId });
    return response.status === 201;
  }

  public async unAssignFromDuty(clubId: string, dutyId: string, assignmentId: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.duties}/${dutyId}/${this.dutyRoutes.assignments}/${assignmentId}`;
    const response = await axios.delete(url);
    return response.status === 200;
  }

  public async getDutyReminderToAssign(token: string): Promise<DutyReminderAcceptance> {
    const url = `${this.BASE_URL}/${this.dutyReminderRoutes.dutyReminder}?token=${token}`;
    const response = await axios.get(url);

    return response.data;
  }

  public async assignToDutyAnonymous(token: string): Promise<boolean> {
    const url = `${this.BASE_URL}/${this.dutyReminderRoutes.dutyReminder}/${this.dutyReminderRoutes.assign}`;
    const response = await axios.post(url, { token });

    return response.status == 201;
  }

  public async getDutyReminderFromToken(clubId: string, token: string): Promise<DutyMemberAcceptance> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.duties}/${this.dutyRoutes.token}`;
    const response = await axios.post(url, { token });

    return response.data;
  }

  public async getDutySeasonPoints(clubId: string): Promise<DutySeasonPoints> {
    const url = `${this.BASE_URL}/${this.routes.clubs}/${clubId}/${this.dutyRoutes.duties}/${this.dutyRoutes.seasonPoints}`;
    const response = await axios.get(url);

    return response.data;
  }
}