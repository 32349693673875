import { RouteConfig } from 'vue-router';

const dashboardRoutes: RouteConfig[] = [
  {
    name: 'Dashboard',
    path: '/',
    component: () => import('../pages/Dashboard.vue')
  }
]

export default dashboardRoutes;