import { RouteConfig } from 'vue-router';

const teamsRoutes: RouteConfig[] = [
  {
    name: 'AllTeams',
    path: '/teams',
    component: () => import('../pages/AllTeams.vue')
  },
  {
    name: 'TeamInfo',
    path: '/teams/:id',    
    meta: {
      title: 'LISAX Team'
    },
    component: () => import('../pages/TeamInfo.vue')
  }
]

export default teamsRoutes;