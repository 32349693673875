export interface CustomFieldAnswer {
    customFieldTitle: string,
    customFieldDescription: string,
    customFieldId: string,
    customFieldValueId: string,
    customFieldType: CustomFieldType,
    customFieldTypeAsInt: number,
    customFieldOrder: number,
    boolValue: boolean | null,
    textValue: string | null,
    intValue: number | null,
    canEdit: boolean,
    personCanEdit: boolean,
    personCanChangeValue: boolean,
    datetimeValue: string | null,
    guidValue: string | null,
    customFieldGroupId: string,
    groupName: string,
    groupDescription: string,
    groupOrder: number,
    selectListAvailableValues: AvailableValue[],
    valueAsString: string | null,
}

export interface AvailableValue {
    guid: string,
    name: string
}

export enum CustomFieldType {
    Bool = 'bool',
    Int = 'int',
    Date = 'date',
    Text = 'text',
    SelectList = 'selectList',
    Color = 'colorpicker',
    DecisionSelectList = 'decisionSelectList',
}

