import { RouteConfig } from 'vue-router';

const newsRoutes: RouteConfig[] = [
  {
    name: 'News',
    path: '/nieuws',
    component: () => import('../pages/News.vue')
  }
]

export default newsRoutes;